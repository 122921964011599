import React, { useEffect, useState } from "react";
import {
  Card,
  Header,
  Image,
  Button,
  Icon,
  Label,
  Segment,
  Dimmer,
} from "semantic-ui-react";
import Practice from "../images/practice.png";
import Lesson from "../images/lesson.png";
import Challenge from "../images/challenge.png";
import Code from "../images/code.png";
import Diagram from "../images/diagram.png";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Tabs({ props, properties }) {
  const suscription = properties.remaining_days < 0 ? false : true;
  const { t, i18n } = useTranslation();
  const isEspanish = i18n.resolvedLanguage === "es" ? true : false;
  const role =
    properties.role === "SisAdmin" || properties.role === "Admin"
      ? true
      : false;
  const arrayProjects = properties.courses;
  const projects = arrayProjects && arrayProjects.includes("Robotica");
  const [locked, setLocked] = useState(true);

  const downloadFile = (ref) => {
    window.location.href = `https://${ref}`;
  };

  useEffect(() => {
    async function getData() {
      if (role) {
        setLocked(false);
      } else if (suscription && projects) {
        setLocked(false);
      }
    }
    getData();
  }, []);

  if (props.Title !== "Archivos") {
    return (
      <>
        <Header as="h2">
          {isEspanish ? props.Title : props.TitleCA}{" "}
          {props.Available ? null : (
            <Label color="red">{t("no_disponible")}</Label>
          )}
        </Header>
        {props.Available ? (
          <Segment inverted color={props.Color} secondary>
            {isEspanish ? props.Description : props.DescriptionCA}
          </Segment>
        ) : null}
        <Dimmer.Dimmable as={Segment} blurring dimmed={locked}>
          <Card.Group itemsPerRow={3} stackable>
            <Card color={props.Available ? "green" : "grey"}>
              <Image
                src={Practice}
                wrapped
                ui={false}
                className={props.Available ? null : "contrast"}
              />
              <Card.Content extra>
                <Card.Header>
                  <NavLink
                    to={`/practices/type=Práctica Interactiva&course=${props.Course}&ref=${props.InteractivePractice}`}
                    onClick={(e) => {
                      if (!props.Available) e.preventDefault();
                    }}
                  >
                    <Button
                      animated
                      color={props.Available ? props.Color : "grey"}
                      size="small"
                      fluid
                      disabled={!props.Available}
                    >
                      <Button.Content visible>
                        {t("prac_interact")}
                      </Button.Content>
                      <Button.Content hidden>
                        <Icon size="small" name="arrow right" />
                      </Button.Content>
                    </Button>
                  </NavLink>
                </Card.Header>
              </Card.Content>
            </Card>
            <Card color={props.Available ? "green" : "grey"}>
              <Image
                src={Lesson}
                wrapped
                ui={false}
                className={props.Available ? null : "contrast"}
              />
              <Card.Content extra>
                <Card.Header>
                  <NavLink
                    to={`/practices/type=Lección Digital&course=${props.Course}&ref=${props.DigitalLesson}`}
                    onClick={(e) => {
                      if (!props.Available) e.preventDefault();
                    }}
                  >
                    <Button
                      animated
                      color={props.Available ? props.Color : "grey"}
                      size="small"
                      fluid
                      disabled={!props.Available}
                    >
                      <Button.Content visible>
                        {" "}
                        {t("lecci_digit")}
                      </Button.Content>
                      <Button.Content hidden>
                        <Icon size="small" name="arrow right" />
                      </Button.Content>
                    </Button>
                  </NavLink>
                </Card.Header>
              </Card.Content>
            </Card>
            <Card color={props.Available ? "green" : "grey"}>
              <Image
                src={Challenge}
                wrapped
                ui={false}
                className={props.Available ? null : "contrast"}
              />
              <Card.Content extra>
                <Card.Header>
                  {" "}
                  <a
                    href={`https://retos-aplicacion-yarvix.s3.us-east-2.amazonaws.com/${props.ApplicationChallenge}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      animated
                      color={props.Available ? props.Color : "grey"}
                      size="small"
                      fluid
                      disabled={!props.Available}
                    >
                      <Button.Content visible>
                        {t("reto_aplica")}
                      </Button.Content>
                      <Button.Content hidden>
                        <Icon size="small" name="arrow right" />
                      </Button.Content>
                    </Button>
                  </a>
                </Card.Header>
              </Card.Content>
            </Card>
          </Card.Group>
          <Dimmer active={locked}>
            <Header as="h3" inverted>
              {!suscription ? t("suscripcion") : t("nivel_suscr")}
              <br />
              <br />
              <Link to="/subscription">
                <Button color={props.Color} size="mini">
                  {t("ir_perfil")}
                </Button>
              </Link>
            </Header>
          </Dimmer>
        </Dimmer.Dimmable>
      </>
    );
  } else {
    return (
      <>
        <Header as="h2">
          {isEspanish ? props.Title : props.TitleCA}{" "}
          {props.Available ? null : (
            <Label color="red">{t("no_disponible")}</Label>
          )}
        </Header>
        {props.Available ? (
          <Segment inverted color={props.Color} secondary>
            {isEspanish ? props.Description : props.DescriptionCA}{" "}
          </Segment>
        ) : null}
        <Dimmer.Dimmable as={Segment} blurring dimmed={locked}>
          <Card.Group itemsPerRow={2} stackable>
            <Card color={props.Available ? "green" : "grey"}>
              <Image
                src={Code}
                wrapped
                ui={false}
                className={props.Available ? null : "contrast"}
              />
              <Card.Content extra>
                <Card.Header>
                  <Button
                    animated
                    color={props.Available ? props.Color : "grey"}
                    size="small"
                    fluid
                    disabled={!props.Available}
                    onClick={() => downloadFile(props.Download1)}
                  >
                    <Button.Content visible>{t("codigos")}</Button.Content>
                    <Button.Content hidden>
                      <Icon size="small" name="arrow right" />
                    </Button.Content>
                  </Button>
                </Card.Header>
              </Card.Content>
            </Card>
            <Card color={props.Available ? "green" : "grey"}>
              <Image
                src={Diagram}
                wrapped
                ui={false}
                className={props.Available ? null : "contrast"}
              />
              <Card.Content extra>
                <Card.Header>
                  <Button
                    animated
                    color={props.Available ? props.Color : "grey"}
                    size="small"
                    fluid
                    disabled={!props.Available}
                    onClick={() => downloadFile(props.Download2)}
                  >
                    <Button.Content visible>{t("diagramas")}</Button.Content>
                    <Button.Content hidden>
                      <Icon size="small" name="arrow right" />
                    </Button.Content>
                  </Button>
                </Card.Header>
              </Card.Content>
            </Card>
          </Card.Group>
          <Dimmer active={locked}>
            <Header as="h3" inverted>
              {!suscription ? t("suscripcion") : t("nivel_suscr")}
              <br />
              <br />
              <Link to="/subscription">
                <Button color={props.Color} size="mini">
                  {t("ir_perfil")}
                </Button>
              </Link>
            </Header>
          </Dimmer>
        </Dimmer.Dimmable>
      </>
    );
  }
}
