import React from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import Header from "../components/Header";
import BreadcrumbTop from "../components/BreadcrumbTop";
import { useTranslation } from "react-i18next";
import IncompleteProfile from "../containers/IncompleteProfile";

export default function Practices({ match, logout, properties }) {
  const type = match.params.type;
  const course = match.params.course;
  const ref = match.params.ref;
  const dataCompleted = properties.data_completed;
  const { t } = useTranslation();
  let breadcrumb;

  if (course === "robotica-y-programacion") {
    breadcrumb = `${t("curso_robo")}`;
  } else if (course === "bionica") {
    breadcrumb = `${t("curso_bion")}`;
  } else if (course === "domotica-y-iot") {
    breadcrumb = `${t("curso_domo")}`;
  } else if (course === "energias-renovables") {
    breadcrumb = `${t("curso_ener")}`;
  } else if (course === "scratch") {
    breadcrumb = `${t("curso_scra")}`;
  } else if (course === "minecraft") {
    breadcrumb = `${t("curso_mine")}`;
  }

  const sections = [
    { content: breadcrumb, destination: `/courses/course=${course}` },
    { content: type, destination: "/null" },
  ];

  return (
    <>
      <Header logout={logout} properties={properties} />
      <div className="padd-top-6 index-bg">
        <BreadcrumbTop sections={sections} />
        <Container>
          <Row>
            <Col sm="12">
              {!dataCompleted && <IncompleteProfile />}
              <Card>
                <CardBody>
                  <Row>
                  <div className="video-wrapper">
                    <iframe
                      src={`https://main.${ref}.amplifyapp.com/story.html`}
                      allowFullScreen
                      className="iframe"
                      title="Cursos - Yarvix"
                    />
                  </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
