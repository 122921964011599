import React from "react";
import { Icon, Message } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default function IncompleteProfile() {
  return (
    <Message color="yellow" floating icon size="big">
      <Icon name="exclamation" />
      <Message.Content>
        <Message.Header>Completar perfil</Message.Header>
        <p>
          Ve a tu <Link to="/profile">perfil</Link> y completa los datos
          faltantes. El día 31 de Diciembre de 2023 será eliminado todo perfil que no esté verificado.
        </p>
      </Message.Content>
    </Message>
  );
}
