import React from "react";
import { Form } from "semantic-ui-react";
import { MultiSelect } from "react-multi-select-component";

export default function MultiCompanyForm({ options, selected, setSelected }) {
  return (
    <>
      <Form.Group widths="equal">
        <div className="required field">
          <label>Cursos a los que puede acceder</label>
          <div className="ui fluid input">
            <MultiSelect
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
            />
          </div>
        </div>
      </Form.Group>
    </>
  );
}
