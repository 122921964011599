import React, { useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Container, Input } from "semantic-ui-react";
import Header from "../components/Header";
import BreadcrumbTop from "../components/BreadcrumbTop";
import Axios from "axios";
import { ROOT_URL } from "../constants/defaultValues";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../components/Loading";
import IncompleteProfile from "../containers/IncompleteProfile";

const notify = (ms) =>
  toast.success(ms, {
    autoClose: 4000,
    theme: "dark",
  });

export default function Subscription({ user, logout, properties, history }) {
  const sections = [{ content: "Código", destination: "/null" }];
  const dataCompleted = properties.data_completed;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const id = user;

  async function findCoupon(val) {
    if (val.length === 12) {
      setLoading(true);

      try {
        //Busca el código ingresado
        await Axios.get(
          `${ROOT_URL}/batch-coupons/?view=searching&coupon=${val}`
        ).then((response) => {
          //Si el código ingresado existe, se realiza la busqueda del mismo para
          //conocer si ya ha sido canjeado
          const res = response.data;
          const payload = {
            Nombre: res.Nombre,
            Cupon: val,
          };

          Axios.post(`${ROOT_URL}/redeemed-coupons/`, payload).then(
            (response) => {
              if (response.status === 201) {
                notify(response.data.message);

                const payloadUser = {
                  Prueba: false,
                  Suscripcion: "Tienda",
                  Cursos: res.Cursos,
                  Dias: res.DiasSuscripcion,
                };

                Axios.patch(`${ROOT_URL}/users/${id}/cupon`, payloadUser).then(
                  (response) => {
                    setLoading(false);
                    history.push("/profile");
                  }
                );
              } else {
                notify(response.data.message);
                setLoading(false);
                setError(true);
                return;
              }
            }
          );
        });
      } catch (error) {
        setLoading(false);
        setError(true);
        notify(error.response.data.message);
      }
    }
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Header logout={logout} properties={properties} />
      <ToastContainer />
      <div className="padd-top-6 index-bg">
        <BreadcrumbTop sections={sections} />
        <Container>
          <Row>
            <Col sm="12">
              {!dataCompleted && <IncompleteProfile />}
              <Card>
                <CardBody>
                  <Row>
                    <Container>
                      <>
                        <h1 className="text-center pricing-table-title padd-top-3">
                          Ingresa tu código de suscripción
                        </h1>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="card pricing-card pricing-card-highlighted pricing-plan-pro">
                              <div className="card-body">
                                <p className="pricing-plan-title">
                                  Código de suscripción
                                </p>
                                <Input
                                  action={{
                                    color: "purple",
                                    content: "Canjear",
                                  }}
                                  icon="barcode"
                                  iconPosition="left"
                                  placeholder="Cupón"
                                  loading={loading}
                                  error={error}
                                  disabled={loading}
                                  fluid
                                  size="small"
                                  onChange={(e) => findCoupon(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </Container>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
