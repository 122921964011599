import React, { useState } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import {
  Form as FormSec,
  Card as CardSec,
} from "semantic-ui-react";
import Header from "../components/Header";
import BreadcrumbTop from "../components/BreadcrumbTop";

export default function Contact({ logout, properties }) {
  const sections = [{ content: "Contacto", destination: "/null" }];
  const [infoUser, setInfoUser] = useState(null);
  //Gestiona los cambios en los inputs
  function handleInputChange(e) {
    setInfoUser({
      ...infoUser,
      [e.target.name]: e.target.value,
    });
  }

  //Función asíncrona encargada de mandar el formulario
  //al endpoint login
  async function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <>
      <Header logout={logout} properties={properties} />
      <div className="padd-top-6 index-bg">
        <BreadcrumbTop sections={sections} />
        <Container>
          <Row>
            <Col sm="12">
              <Card className="trans">
                <CardBody>
                  <Row>
                    <CardSec.Group itemsPerRow={1} stackable>
                      <CardSec color="purple">
                        <CardSec.Content extra>
                          <CardSec.Header>
                            <FormSec onSubmit={handleSubmit}>
                              <FormSec.Group widths="equal">
                                <FormSec.Input
                                  fluid
                                  label="Nombre"
                                  placeholder="Nombre"
                                  required
                                  onChange={handleInputChange}
                                  name="Nombre"
                                />
                                <FormSec.Input
                                  fluid
                                  label="Movil"
                                  placeholder="Movil"
                                  required
                                  onChange={handleInputChange}
                                  name="Movil"
                                />
                                <FormSec.Input
                                  fluid
                                  label="Asunto"
                                  placeholder="Asunto"
                                  required
                                  onChange={handleInputChange}
                                  name="Asunto"
                                />
                              </FormSec.Group>
                              <FormSec.Group widths="equal">
                                <FormSec.Input
                                  fluid
                                  label="Movil"
                                  placeholder="Movil"
                                  required
                                  onChange={handleInputChange}
                                  name="Movil"

                                />
                              </FormSec.Group>
                              <FormSec.Button content="Modificar" />
                            </FormSec>
                          </CardSec.Header>
                        </CardSec.Content>
                      </CardSec>
                    </CardSec.Group>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
