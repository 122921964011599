import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import { Placeholder, Button } from "semantic-ui-react";
import Header from "../components/Header";
import BreadcrumbTop from "../components/BreadcrumbTop";
import Axios from "axios";
import { ROOT_URL, /*api_pay*/ } from "../constants/defaultValues";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

export default function CouponList({ logout, properties }) {
  const [companies, setCompanies] = useState(null);
  //const [coupons, setCoupons] = useState(null);
  const [batchCoupons, setBatchCoupons] = useState(null);
  const sections = [{ content: "Lista de cupones", destination: "/null" }];
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <Button size="tiny" color="black" onClick={handleClick}>
        Exportar datos
      </Button>
    );
  };

  useEffect(() => {
    const asyncFunction = async () => {
      try {
        const { data: companies } = await Axios.get(`${ROOT_URL}/companies/`);
        //const { data: coupons } = await Axios.get(`${api_pay}/get_all_coupons`);
        const { data: batchCoupons } = await Axios.get(
          `${ROOT_URL}/batch-coupons/`
        );
        setCompanies(companies);
        //setCoupons(coupons.data.data);
        setBatchCoupons(batchCoupons);
      } catch (error) {
        return error;
      }
    };
    asyncFunction();
  }, []);

  const linkFormatter = (cell, row) => {
    const name = row.Nombre;
    return `https://yarvix.biz/signup/reference=${name}`;
  };

  /*const dateFormatter = (cell, row) => {
    const date = row.redeem_by;
    return new Date(date * 1000).toLocaleString();
  };*/

  const column = [
    {
      filter: textFilter(),
      dataField: "Nombre",
      text: "Nombre",
      sort: true,
    },
    {
      filter: textFilter(),
      dataField: "none",
      text: "Link",
      formatter: linkFormatter,
    },
    {
      filter: textFilter(),
      dataField: "Cursos",
      text: "Cursos Otorgados",
    },
    {
      filter: textFilter(),
      dataField: "DiasSuscripcion",
      text: "Dias Suscripcion",
      sort: true,
    },
    {
      filter: textFilter(),
      dataField: "NoCupones",
      text: "Numero Cupones",
      sort: true,
    },
    {
      filter: textFilter(),
      dataField: "CuponesRestantes",
      text: "Cupones Redimidos",
      sort: true,
    },
  ];

  /*const columnCoupons = [
    {
      filter: textFilter(),
      dataField: "id",
      text: "Nombre",
      sort: true,
    },
    {
      filter: textFilter(),
      dataField: "percent_off",
      text: "% de descuento",
      sort: true,
    },
    {
      filter: textFilter(),
      dataField: "max_redemptions",
      text: "No cupones",
      sort: true,
    },
    {
      filter: textFilter(),
      dataField: "times_redeemed",
      text: "Cupones Redimidos",
      sort: true,
    },
    {
      filter: textFilter(),
      dataField: "redeem_by",
      text: "Fecha validez",
      formatter: dateFormatter,
      sort: true,
    },
  ];*/

  const columnBatchCoupons = [
    {
      filter: textFilter(),
      dataField: "Id",
      text: "Nombre",
      sort: true,
    },
    {
      filter: textFilter(),
      dataField: "DiasSuscripcion",
      text: "Dias Suscripción",
      sort: true,
    },
    {
      filter: textFilter(),
      dataField: "NoCupones",
      text: "No cupones",
      sort: true,
    },
    {
      dataField: "Cupones",
      text: "Id's cupones",
      style: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      formatter: codeFormat,
    },
  ];

  function codeFormat(cell, row) {
    if (typeof cell !== "undefined" && cell !== null) {
      return cell.join(", ");
    }
  }

  return (
    <>
      <Header logout={logout} properties={properties} />
      <div className="padd-top-6 padd-bot-2 index-bg">
        <BreadcrumbTop sections={sections} />
        <Container>
          <Row>
            <Col sm="12">
              <Card className={companies ? null : "trans"}>
                <CardBody>
                  <Row>
                    {companies ? (
                      <ToolkitProvider
                        bootstrap4
                        keyField="Id"
                        data={companies}
                        columns={column}
                        exportCSV={{
                          onlyExportFiltered: true,
                          exportAll: false,
                        }}
                      >
                        {(props) => (
                          <>
                            <div className="text-zero top-right-button-container">
                              <MyExportCSV {...props.csvProps} />
                            </div>
                            <br />
                            <br />
                            <BootstrapTable
                              {...props.baseProps}
                              pagination={paginationFactory()}
                              filter={filterFactory()}
                              hover
                              striped
                              condensed
                              wrapperClasses="table-responsive"
                              bordered={false}
                            />
                          </>
                        )}
                      </ToolkitProvider>
                    ) : (
                      <Placeholder fluid>
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                      </Placeholder>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <br />
        {/*<Container>
          <Row>
            <Col sm="12">
              <Card className={coupons ? null : "trans"}>
                <CardBody>
                  <Row>
                    {coupons ? (
                      <ToolkitProvider
                        bootstrap4
                        keyField="id"
                        data={coupons}
                        columns={columnCoupons}
                        exportCSV={{
                          onlyExportFiltered: true,
                          exportAll: false,
                        }}
                      >
                        {(props) => (
                          <>
                            <div className="text-zero top-right-button-container">
                              <MyExportCSV {...props.csvProps} />
                            </div>
                            <br />
                            <br />
                            <BootstrapTable
                              {...props.baseProps}
                              pagination={paginationFactory()}
                              filter={filterFactory()}
                              hover
                              striped
                              condensed
                              wrapperClasses="table-responsive"
                              bordered={false}
                            />
                          </>
                        )}
                      </ToolkitProvider>
                    ) : (
                      <Placeholder fluid>
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                      </Placeholder>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>*/}
        <br />
        <Container>
          <Row>
            <Col sm="12">
              <Card className={batchCoupons ? null : "trans"}>
                <CardBody>
                  <Row>
                    {batchCoupons ? (
                      <ToolkitProvider
                        bootstrap4
                        keyField="Id"
                        data={batchCoupons}
                        columns={columnBatchCoupons}
                        exportCSV={{
                          onlyExportFiltered: true,
                          exportAll: false,
                        }}
                      >
                        {(props) => (
                          <>
                            <div className="text-zero top-right-button-container">
                              <MyExportCSV {...props.csvProps} />
                            </div>
                            <br />
                            <br />
                            <BootstrapTable
                              {...props.baseProps}
                              pagination={paginationFactory()}
                              filter={filterFactory()}
                              hover
                              striped
                              condensed
                              wrapperClasses="table-responsive"
                              bordered={false}
                              bodyStyle={{
                                width: 500,
                                maxWidth: 500,
                                wordBreak: "break-all",
                              }}
                            />
                          </>
                        )}
                      </ToolkitProvider>
                    ) : (
                      <Placeholder fluid>
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                      </Placeholder>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
