export const ROOT_URL = "https://1ss0h7t09a.execute-api.us-east-2.amazonaws.com/production/api/v1";
//export const ROOT_URL = "http://127.0.0.1:5000/api/v1";

export const password_reset = "https://4ywtg30qj0.execute-api.us-east-2.amazonaws.com/production/api/v1";
//export const password_reset = "http://127.0.0.1:5000/api/v1";

export const schengenCountries = [
  "Austria",
  "Belgium",
  "Czechia",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Iceland",
  "Italy",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
];

export const toastTypes = {
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
};
