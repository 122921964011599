import React, { useState } from "react";
import { Card, CardBody, FormGroup, Col, Badge } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import LogoReset from "../images/form-reset-password.png";
import { Container, Button } from "semantic-ui-react";
import Axios from "axios";
import { password_reset } from "../constants/defaultValues";
import { useParams } from "react-router-dom";
import { showSuccessToast, showErrorToast } from "../components/Notify";

export default function PasswordReset({ history }) {
  const [loading, setLoading] = useState(false);
  const { token } = useParams();

  //formSchema se encarga de las validaciones y tipo de dato de los campos
  const formSchema = Yup.object().shape({
    Contrasena: Yup.string().required("Escriba su contraseña"),
    Confirmacion: Yup.string()
      .required("Repita su contraseña")
      .oneOf([Yup.ref("Contrasena"), null], "Las contraseñas deben coincidir"),
  });

  async function onSubmit(values) {
    setLoading(true);

    try {
      await Axios.get(`${password_reset}/reset/${token}`).then((response) => {
        const payload = {
          Contrasena: values.Contrasena,
          Email: response.data.email,
        };

        Axios.patch(`${password_reset}/reset/`, payload).then((response) => {
          showSuccessToast({
            title: "Éxito",
            message: response.data.message,
          });
          setLoading(false);

          history.push("/");
        });
      });
    } catch (error) {
      showErrorToast({
        title: "Mensaje",
        message: "El link de confirmacion es invalido o ha expirado",
      });
      setLoading(false);
    }
  }

  return (
    <div className="reset-bg">
      <Container>
        <Col sm="12" md={{ size: 4, offset: 4 }} className="padd-top-8">
          <Card className="bg-color-segment">
            <CardBody>
              <span className="center padd-bot-3">
                <img src={LogoReset} alt="logo" className="img-fluid" />
              </span>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  Contrasena: "",
                  Confirmacion: "",
                }}
                validationSchema={formSchema}
                onSubmit={onSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    <FormGroup row>
                      <Col sm="12" className="padd-bot-3">
                        <FormGroup>
                          <Field
                            type="password"
                            name="Contrasena"
                            className="input-form"
                            placeholder="Contraseña"
                            required
                          />
                          {errors.Contrasena && touched.Contrasena ? (
                            <div>
                              <Badge className="badge-color">
                                {errors.Contrasena}
                              </Badge>
                            </div>
                          ) : null}
                        </FormGroup>
                        <FormGroup>
                          <Field
                            type="password"
                            name="Confirmacion"
                            className="input-form"
                            placeholder="Repita su contraseña"
                            required
                          />
                          {errors.Confirmacion && touched.Confirmacion ? (
                            <div>
                              <Badge className="badge-color">
                                {errors.Confirmacion}
                              </Badge>
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="12" className="padd-bot-3">
                        <FormGroup>
                          <Button
                            content="Cambiar contraseña"
                            color="purple"
                            type="submit"
                            fluid
                            loading={loading}
                          />
                        </FormGroup>
                      </Col>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </div>
  );
}

