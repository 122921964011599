import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import {
  Placeholder,
  Form as FormSec,
  Card as CardSec,
} from "semantic-ui-react";
import Axios from "axios";
import { ROOT_URL } from "../constants/defaultValues";
import Header from "../components/Header";
import BreadcrumbTop from "../components/BreadcrumbTop";

export default function User({ match, logout, properties }) {
  const [infoUser, setInfoUser] = useState(null);
  const id = match.params.userid;
  const sections = [{ content: "Usuario", destination: "/null" }];

  useEffect(() => {
    async function getData() {
      try {
        const { data: user } = await Axios.get(`${ROOT_URL}/users/${id}`);
        setInfoUser(user);
      } catch (error) {}
    }
    getData();
  }, [id]);

  //Gestiona los cambios en los inputs
  function handleInputChange(e) {
    setInfoUser({
      ...infoUser,
      [e.target.name]: e.target.value,
    });
  }

  //Función asíncrona encargada de mandar el formulario
  //al endpoint login
  async function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <>
      <Header logout={logout} properties={properties} />
      <div className="padd-top-6 index-bg">
        <BreadcrumbTop sections={sections} />
        <Container>
          <Row>
            <Col sm="12">
              <Card className="trans">
                <CardBody>
                  <Row>
                    {infoUser ? (
                      <>
                        <CardSec.Group itemsPerRow={1} stackable>
                          <CardSec color="purple">
                            <CardSec.Content extra>
                              <CardSec.Header>
                                <FormSec onSubmit={handleSubmit}>
                                  <FormSec.Group widths="equal">
                                    <FormSec.Input
                                      fluid
                                      label="Nombre"
                                      placeholder="Nombre"
                                      required
                                      onChange={handleInputChange}
                                      name="Nombre"
                                      value={infoUser.Nombre}
                                    />
                                    <FormSec.Input
                                      fluid
                                      label="Apellidos"
                                      placeholder="Apellidos"
                                      required
                                      onChange={handleInputChange}
                                      name="Apellidos"
                                      value={infoUser.Apellidos}
                                    />
                                    <FormSec.Input
                                      fluid
                                      label="Email"
                                      placeholder="Email"
                                      required
                                      onChange={handleInputChange}
                                      name="Email"
                                      value={infoUser.Email}
                                    />
                                    <FormSec.Input
                                      fluid
                                      label="Movil"
                                      placeholder="Movil"
                                      required
                                      onChange={handleInputChange}
                                      name="Movil"
                                      value={infoUser.Movil}
                                      readOnly
                                    />
                                    <FormSec.Input
                                      fluid
                                      label="Edad"
                                      placeholder="Edad"
                                      required
                                      onChange={handleInputChange}
                                      name="Edad"
                                      value={infoUser.Edad}
                                      readOnly
                                    />
                                  </FormSec.Group>
                                  <FormSec.Group widths="equal">
                                    <FormSec.Input
                                      fluid
                                      label="Empresa"
                                      value={
                                        infoUser.Propiedad === "Yarvix"
                                          ? "Yarvix"
                                          : infoUser.Propiedad
                                      }
                                      readOnly
                                    />
                                    <FormSec.Input
                                      fluid
                                      label="Cursos"
                                      placeholder="Cursos"
                                      required
                                      onChange={handleInputChange}
                                      name="Cursos"
                                      value={infoUser.Cursos}
                                      readOnly
                                    />
                                    {!infoUser.DatosPago && (
                                      <FormSec.Input
                                        fluid
                                        label="Suscripcion"
                                        placeholder="Suscripcion"
                                        required
                                        onChange={handleInputChange}
                                        name="Suscripcion"
                                        value={infoUser.Suscripcion}
                                        readOnly
                                      />
                                    )}
                                    <FormSec.Input
                                      fluid
                                      label="Prueba"
                                      placeholder="Prueba"
                                      required
                                      onChange={handleInputChange}
                                      name="Prueba"
                                      value={infoUser.Prueba ? "Si" : "No"}
                                      readOnly
                                    />
                                    <FormSec.Input
                                      fluid
                                      label="Inicio registro"
                                      value={new Date(
                                        infoUser.FechaCreacion * 1000
                                      ).toLocaleDateString()}
                                      readOnly
                                    />
                                    <FormSec.Input
                                      fluid
                                      label="Fin suscripción (registro)"
                                      value={new Date(
                                        infoUser.FinSuscripcion * 1000
                                      ).toLocaleDateString()}
                                      readOnly
                                    />
                                  </FormSec.Group>
                                  <FormSec.Button
                                    content="Modificar"
                                    disabled
                                  />
                                </FormSec>
                              </CardSec.Header>
                            </CardSec.Content>
                          </CardSec>
                          {infoUser.Ubicacion && (
                            <CardSec color="purple">
                              <CardSec.Content extra>
                                <CardSec.Header>
                                  <FormSec>
                                    <FormSec.Group widths="equal">
                                      <FormSec.Input
                                        fluid
                                        label="Ciudad"
                                        value={infoUser.Ubicacion.city}
                                        readOnly
                                      />
                                      <FormSec.Input
                                        fluid
                                        label="País"
                                        value={infoUser.Ubicacion.countryName}
                                        readOnly
                                      />
                                      <FormSec.Input
                                        fluid
                                        label="Código de país"
                                        value={infoUser.Ubicacion.countryCode}
                                        readOnly
                                      />
                                      <FormSec.Input
                                        fluid
                                        label="Zona horaria"
                                        value={infoUser.Ubicacion.timezone}
                                        readOnly
                                      />
                                    </FormSec.Group>
                                  </FormSec>
                                </CardSec.Header>
                              </CardSec.Content>
                            </CardSec>
                          )}
                          {infoUser.DatosPago && (
                            <CardSec color="purple">
                              <CardSec.Content extra>
                                <CardSec.Header>
                                  <FormSec>
                                    <FormSec.Group widths="equal">
                                      <FormSec.Input
                                        fluid
                                        label="Fecha de pago"
                                        value={new Date(
                                          infoUser.DatosPago.FechaPago * 1000
                                        ).toLocaleDateString()}
                                        readOnly
                                      />
                                      <FormSec.Input
                                        fluid
                                        label="Inicio de suscripción"
                                        value={new Date(
                                          infoUser.DatosPago.PeriodoInicio *
                                            1000
                                        ).toLocaleDateString()}
                                        readOnly
                                      />
                                      <FormSec.Input
                                        fluid
                                        label="Fin de suscripción"
                                        value={new Date(
                                          infoUser.DatosPago.PeriodoFin * 1000
                                        ).toLocaleDateString()}
                                        readOnly
                                      />
                                    </FormSec.Group>
                                    <FormSec.Group widths="equal">
                                      <FormSec.Input
                                        fluid
                                        label="Id de la suscripción"
                                        value={infoUser.DatosPago.IdSuscripcion}
                                        readOnly
                                      />
                                      <FormSec.Input
                                        fluid
                                        label="Tipo de suscripción"
                                        value={infoUser.Suscripcion}
                                        readOnly
                                      />
                                    </FormSec.Group>
                                  </FormSec>
                                </CardSec.Header>
                              </CardSec.Content>
                            </CardSec>
                          )}
                        </CardSec.Group>
                      </>
                    ) : (
                      <Placeholder fluid>
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                      </Placeholder>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
