import React, { useEffect, useState } from "react";
import { Card, CardBody, FormGroup, Col, Badge } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import LogoLogin from "../images/form-signup.png";
import { ToastContainer, toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { Container, Button, Form as FormSec } from "semantic-ui-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Axios from "axios";

export default function SignUp({ signup, match }) {
  const reference =
    typeof match.params.reference === "string"
      ? match.params.reference
      : "Yarvix";
  const [geolocation, setGeolocation] = useState(null);
  const [ref] = useState(reference);
  const isSL = reference === "Yarvix" ? "Yarvix" : reference;
  const [state, setState] = useState({
    Movil: "",
  });
  const types = ["Alumno", "Profesor", "Invitado"];
  const genders = ["Hombre", "Mujer"];

  const notify = (ms) =>
    toast.error(ms, {
      autoClose: 4000,
      theme: "dark",
    });

  //formSchema se encarga de las validaciones y tipo de dato de los campos
  const formSchema = Yup.object().shape({
    Nombre: Yup.string().required("Escriba su nombre"),
    Apellidos: Yup.string().required("Escriba sus apellidos"),
    Edad: Yup.number().required("Escriba su edad"),
    Email: Yup.string().email().required("Proporcione un email"),
    Contrasena: Yup.string().required("Escriba su contraseña"),
  });

  useEffect(() => {
    const GetCountry = async () => {
      document.title = "Registro | Yarvix";
      try {
        const [getLocal] = await Promise.all([
          Axios.get("https://ipapi.co/json/").then(({ data }) => data),
        ]);
        setGeolocation({
          ip: getLocal.ip,
          countryName: getLocal.country_name,
          countryCode: getLocal.country_code,
          city: getLocal.city,
          timezone: getLocal.timezone,
          cp: getLocal.postal,
          currency: getLocal.currency,
          latitude: getLocal.latitude.toString(),
          longitude: getLocal.longitude.toString(),
          languages: getLocal.languages,
        });
      } catch (error) {
        return error;
      }
    };
    GetCountry();
  }, []);

  async function onSubmit(values) {
    const payload = {
      ...values,
      Movil: state.Movil,
      Email: values.Email.toLowerCase(),
      Ubicacion: geolocation,
      Suscripcion: ref === "Stripe" ? "CuponStripe" : "Web",
    };
    
    try {
      await signup(payload);
    } catch (error) {
      notify(error.response.data.message);
    }
  }

  return (
    <div className="padd-top-3 signup-bg">
      <ToastContainer />
      <Container>
        <Col sm="12" md={{ size: 5, offset: 0 }}>
          <Card className="bg-color-segment">
            <CardBody>
              <span className="center padd-bot-3">
                <img src={LogoLogin} alt="logo" className="img-fluid" />
              </span>
              <Formik
                initialValues={{
                  Nombre: "",
                  Apellidos: "",
                  Edad: 1,
                  Email: "",
                  Contrasena: "",
                  Prueba: false,
                  Rol: "Usuario",
                  Suscripcion: "Web",
                  Cursos: [
                    "Robotica",
                    "Domotica",
                    "Bionica",
                    "Energias Renovables",
                    "Scratch",
                    "Minecraft",
                  ],
                  Propiedad: isSL,
                  Genero: "Mujer",
                  Tipo: "Invitado",
                }}
                validationSchema={formSchema}
                onSubmit={onSubmit}
              >
                {({ errors, touched, handleChange, handleBlur, values }) => (
                  <Form>
                    <FormGroup row>
                      <Col sm="4" className="padd-bot-3">
                        <FormGroup>
                          <Field
                            type="text"
                            name="Nombre"
                            className="input-form"
                            placeholder="Nombre"
                            required
                          />
                          {errors.Nombre && touched.Nombre ? (
                            <div>
                              <Badge className="badge-color">
                                {errors.Nombre}
                              </Badge>
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="8" className="padd-bot-3">
                        <FormGroup>
                          <Field
                            type="text"
                            name="Apellidos"
                            className="input-form"
                            placeholder="Apellidos"
                            required
                          />
                          {errors.Apellidos && touched.Apellidos ? (
                            <div>
                              <Badge className="badge-color">
                                {errors.Apellidos}
                              </Badge>
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="4" className="padd-bot-3">
                        <FormSec.Group widths="equal">
                          <select
                            name="Genero"
                            className="input-form"
                            value={values.Genero}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            {genders.map((option, index) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </FormSec.Group>
                      </Col>
                      <Col sm="3" className="padd-bot-3">
                        <FormGroup>
                          <Field
                            type="number"
                            min={1}
                            max={60}
                            name="Edad"
                            className="input-form"
                            placeholder="Edad"
                            required
                          />
                          {errors.Edad && touched.Edad ? (
                            <div>
                              <Badge className="badge-color">
                                {errors.Edad}
                              </Badge>
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="5" className="padd-bot-3">
                        <FormGroup>
                          <PhoneInput
                            required
                            inputClass="input-form"
                            country={"mx"}
                            placeholder={"Número celular"}
                            value={state.Movil}
                            onChange={(val, con) => {
                              setState({
                                Movil: val,
                              });
                            }}
                            enableSearch="true"
                            inputProps={{
                              required: true,
                            }}
                            isValid={(value) => {
                              if (value.match("^[0-9]{13,14}$")) {
                                return "Su celular debe contener entre 10 y 14 números";
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12" className="padd-bot-3">
                        <FormGroup>
                          <Field
                            type="email"
                            name="Email"
                            className="input-form"
                            placeholder="Email"
                            required
                          />
                          {errors.Email && touched.Email ? (
                            <div>
                              <Badge className="badge-color">
                                {errors.Email}
                              </Badge>
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="12" className="padd-bot-3">
                        <FormGroup>
                          <Field
                            type="password"
                            name="Contrasena"
                            className="input-form"
                            placeholder="Contraseña"
                            required
                          />
                          {errors.Contrasena && touched.Contrasena ? (
                            <div>
                              <Badge className="badge-color">
                                {errors.Contrasena}
                              </Badge>
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="12" className="padd-bot-3">
                        <FormSec.Group widths="equal">
                          <select
                            name="Tipo"
                            className="input-form"
                            value={values.Tipo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            {types.map((option, index) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </FormSec.Group>
                      </Col>
                      <Col sm="12" className="padd-bot-3">
                        <FormGroup>
                          <Button
                            content="Dar de alta en Yarvix"
                            color="purple"
                            type="submit"
                            fluid
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12" className="padd-bot-3">
                        <FormGroup>
                          <Button fluid type="button">
                            <NavLink to={"/"}>
                              ¿Ya tienes una cuenta? Inicia sesión
                            </NavLink>
                          </Button>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </div>
  );
}
