import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import {
  Form as FormSec,
  Card as CardSec,
  Message,
  Icon,
} from "semantic-ui-react";
import Header from "../components/Header";
import BreadcrumbTop from "../components/BreadcrumbTop";
import { ToastContainer, toast } from "react-toastify";
import Axios from "axios";

export default function Verify({ logout, properties, match }) {
  const sections = [{ content: "Verificación", destination: "/null" }];
  const token =
    typeof match.params.token === "string" ? match.params.token : false;
  const [send, setSend] = useState(false);
  const [error, setError] = useState({
    msg: "",
    status: false,
  });

  const notify = (ms) =>
    toast.success(ms, {
      autoClose: 4000,
    });

  useEffect(() => {
    if (token) {
      if (properties.verify_email) {
        window.location = "/profile";
      } else {
        async function setToken() {
          try {
            const { data } = await Axios.get(
              `https://9akuy26qo8.execute-api.us-east-2.amazonaws.com/production/api/v1/confirm/${token}`
            );
            notify(data.message);
            window.location = "/profile";
          } catch (error) {
            console.log(error);
            setError({
              msg: "El link de confirmacion es invalido o ha expirado",
              status: true,
            });
          }
        }
        setToken();
      }
    }
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    const { data } = await Axios.post(
      `https://9akuy26qo8.execute-api.us-east-2.amazonaws.com/production/api/v1/send`,
      {
        Email: properties.email,
      }
    );
    setSend(true);
    notify(data.message);
  }

  console.log(token);

  return (
    <>
      <Header logout={logout} properties={properties} />
      <div className="padd-top-6 index-bg">
        <ToastContainer />
        <BreadcrumbTop sections={sections} />
        <Container>
          <Row>
            <Col sm="12">
              <Card className="trans">
                <CardBody>
                  <Row>
                    <CardSec.Group itemsPerRow={1} stackable>
                      <CardSec color="purple">
                        <CardSec.Content extra>
                          <CardSec.Header>
                            <FormSec onSubmit={handleSubmit}>
                              <Message
                                color={send ? "yellow" : "red"}
                                floating
                                icon
                                size="big"
                              >
                                <Icon name="exclamation" />
                                <Message.Content>
                                  <Message.Header>
                                    Confirmar cuenta
                                  </Message.Header>
                                  <p>
                                    {send
                                      ? "Revisa tu cuenta de correo y da clic en confirmar cuenta."
                                      : error.status
                                      ? error.msg
                                      : "Tu cuenta no ha sido verificada, da clic en el botón de abajo y verifica la cuenta para seguir usando la plataforma."}
                                  </p>
                                </Message.Content>
                              </Message>
                              {!send && (
                                <FormSec.Button
                                  color="red"
                                  content={
                                    error.status
                                      ? "Volver a mandar"
                                      : "Verificar"
                                  }
                                />
                              )}
                            </FormSec>
                          </CardSec.Header>
                        </CardSec.Content>
                      </CardSec>
                    </CardSec.Group>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
