import React from "react";

export default function Iframe({ props }) {
  const refUrl = props.Url;

  return (
    <div className="video-wrapper">
      <iframe
        src={`https://main.${refUrl}.amplifyapp.com/story.html`}
        className="iframe"
        sandbox="allow-scripts allow-same-origin"
        title="Primeros pasos | Yarvix"
      />
    </div>
  );
}
