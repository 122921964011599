import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Badge,
  FormGroup,
} from "reactstrap";
import { Placeholder, Card as CardSec, Button } from "semantic-ui-react";
import Axios from "axios";
import { ROOT_URL } from "../constants/defaultValues";
import Header from "../components/Header";
import BreadcrumbTop from "../components/BreadcrumbTop";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { ToastContainer, toast } from "react-toastify";

const notify = (ms) =>
  toast.error(ms, {
    autoClose: 4000,
    theme: "dark",
  });

export default function Profile({ user, logout, properties, history }) {
  const id = user;
  const sections = [{ content: "Perfil", destination: "/null" }];
  const types = ["Alumno", "Profesor", "Invitado"];
  const genders = ["Hombre", "Mujer"];

  const [infoUser, setInfoUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [geolocation, setGeolocation] = useState(null);
  const [phone, setPhone] = useState({
    Movil: "",
  });

  const formSchema = Yup.object().shape({
    Nombre: Yup.string().required("Escriba su nombre"),
    Apellidos: Yup.string().required("Escriba sus apellidos"),
    Edad: Yup.number().required("Escriba su edad"),
  });

  useEffect(() => {
    async function getData() {
      try {
        const { data: user } = await Axios.get(`${ROOT_URL}/users/${id}`);
        const { data: location } = await Axios.get("https://ipapi.co/json/");

        setInfoUser(user);

        setGeolocation({
          ip: location.ip,
          countryName: location.country_name,
          countryCode: location.country_code,
          city: location.city,
          timezone: location.timezone,
          cp: location.postal,
          currency: location.currency,
          latitude: location.latitude.toString(),
          longitude: location.longitude.toString(),
          languages: location.languages,
        });

        setPhone({
          Movil: toString(user.Movil),
        });

        setLoading(false);
      } catch (error) {}
    }
    getData();
  }, [id]);

  async function onSubmit(values) {
    if (values.Genero === undefined) {
      notify("Seleccione un genero");
      return;
    } else if (values.Tipo === undefined) {
      notify("Seleccione un tipo de usuario");
      return;
    }

    setLoading(true);

    const payload = {
      ...values,
      Movil: phone.Movil,
      Ubicacion:
        infoUser.Ubicacion === undefined ? geolocation : infoUser.Ubicacion,
    };

    try {
      const { data } = await Axios.patch(`${ROOT_URL}/users/${id}`, payload);
      notify(data.message);
      history.push("/");
    } catch (error) {
      notify(error.response.data.message);
      history.push("/");
    }
  }

  if (!infoUser) return;

  const {
    Email,
    Apellidos,
    Edad,
    Cursos,
    FechaCreacion,
    FinSuscripcion,
    Genero,
    Nombre,
    Tipo,
  } = infoUser;

  return (
    <>
      <Header logout={logout} properties={properties} />
      <div className="padd-top-6 index-bg">
        <ToastContainer />
        <BreadcrumbTop sections={sections} />
        <Container>
          <Row>
            <Col sm="12">
              <Card className="trans">
                <CardBody>
                  <Row>
                    {!loading ? (
                      <CardSec.Group itemsPerRow={1} stackable>
                        <CardSec color="purple">
                          <CardSec.Content extra>
                            <CardSec.Header>
                              <Formik
                                initialValues={{
                                  Nombre: Nombre,
                                  Apellidos: Apellidos,
                                  Edad: Edad,
                                  Genero: Genero,
                                  Tipo: Tipo,
                                }}
                                validationSchema={formSchema}
                                onSubmit={onSubmit}
                              >
                                {({
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  values,
                                }) => (
                                  <Form>
                                    <FormGroup row>
                                      <Col sm="2" className="padd-bot-3">
                                        <FormGroup className="required">
                                          <label>Nombre</label>
                                          <Field
                                            type="text"
                                            name="Nombre"
                                            className="input-form"
                                            placeholder="Nombre"
                                            required
                                          />
                                          {errors.Nombre && touched.Nombre ? (
                                            <div>
                                              <Badge className="badge-color">
                                                {errors.Nombre}
                                              </Badge>
                                            </div>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                      <Col sm="3" className="padd-bot-3">
                                        <FormGroup className="required">
                                          <label>Apellidos</label>
                                          <Field
                                            type="text"
                                            name="Apellidos"
                                            className="input-form"
                                            placeholder="Apellidos"
                                            required
                                          />
                                          {errors.Apellidos &&
                                          touched.Apellidos ? (
                                            <div>
                                              <Badge className="badge-color">
                                                {errors.Apellidos}
                                              </Badge>
                                            </div>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                      <Col sm="3" className="padd-bot-3">
                                        <FormGroup className="required">
                                          <label>Genero</label>
                                          <select
                                            name="Genero"
                                            className="input-form"
                                            value={values.Genero}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          >
                                            {genders.map((option, index) => (
                                              <option
                                                key={index}
                                                value={option}
                                              >
                                                {option}
                                              </option>
                                            ))}
                                          </select>
                                        </FormGroup>
                                      </Col>
                                      <Col sm="1" className="padd-bot-3">
                                        <FormGroup className="required">
                                          <label>Edad</label>
                                          <Field
                                            type="number"
                                            min={1}
                                            name="Edad"
                                            className="input-form"
                                            placeholder="Edad"
                                            required
                                          />
                                          {errors.Edad && touched.Edad ? (
                                            <div>
                                              <Badge className="badge-color">
                                                {errors.Edad}
                                              </Badge>
                                            </div>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                      <Col sm="3" className="padd-bot-3">
                                        <FormGroup>
                                          <label>Móvil</label>
                                          <PhoneInput
                                            inputClass="input-form"
                                            country={"es"}
                                            placeholder={"Móvil"}
                                            value={phone.Movil}
                                            onChange={(val, con) => {
                                              setPhone({
                                                Movil: val,
                                              });
                                            }}
                                            enableSearch="true"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col sm="3" className="padd-bot-3">
                                        <FormGroup>
                                          <label>Email</label>
                                          <Field
                                            name="Email"
                                            className="input-form"
                                            disabled={true}
                                            value={Email}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col sm="3" className="padd-bot-3">
                                        <FormGroup className="required">
                                          <label>Tipo</label>
                                          <select
                                            name="Tipo"
                                            className="input-form"
                                            value={values.Tipo}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          >
                                            {types.map((option, index) => (
                                              <option
                                                key={index}
                                                value={option}
                                              >
                                                {option}
                                              </option>
                                            ))}
                                          </select>
                                        </FormGroup>
                                      </Col>
                                      <Col sm="2" className="padd-bot-3">
                                        <FormGroup>
                                          <label>Fecha de alta</label>
                                          <Field
                                            className="input-form"
                                            value={new Date(
                                              FechaCreacion * 1000
                                            ).toLocaleDateString()}
                                            name="FechaCreacion"
                                            disabled={true}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col sm="2" className="padd-bot-3">
                                        <FormGroup>
                                          <label>Fin de suscripción</label>
                                          <Field
                                            className="input-form"
                                            value={new Date(
                                              FinSuscripcion * 1000
                                            ).toLocaleDateString()}
                                            name="FinSuscripcion"
                                            disabled={true}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col sm="2" className="padd-bot-3">
                                        <FormGroup>
                                          <label>Cursos</label>
                                          <Field
                                            className="input-form"
                                            value={Cursos}
                                            name="Cursos"
                                            disabled={true}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col sm="12" className="padd-bot-3">
                                        <FormGroup>
                                          <Button
                                            content="Actualizar"
                                            color="purple"
                                            type="submit"
                                          />
                                        </FormGroup>
                                      </Col>
                                    </FormGroup>
                                  </Form>
                                )}
                              </Formik>
                            </CardSec.Header>
                          </CardSec.Content>
                        </CardSec>
                      </CardSec.Group>
                    ) : (
                      <Placeholder fluid>
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                      </Placeholder>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
