import React, { useState } from "react";
import { Card, CardBody, FormGroup, Col, Badge } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import LogoReset from "../images/form-reset-password.png";
import { NavLink } from "react-router-dom";
import { Container, Button } from "semantic-ui-react";
import Axios from "axios";
import { password_reset } from "../constants/defaultValues";
import { showSuccessToast, showErrorToast } from "../components/Notify";

export default function ForgotPassword() {
  const [send, setSend] = useState(false);
  const [loading, setLoading] = useState(false);

  //formSchema se encarga de las validaciones y tipo de dato de los campos
  const formSchema = Yup.object().shape({
    Email: Yup.string().email().required("Proporcione un email"),
  });

  async function onSubmit(values) {
    setLoading(true);

    const payload = {
      Email: values.Email.toLowerCase(),
    };

    try {
      const { data } = await Axios.post(
        `${password_reset}/reset/`,
        payload
      );

      showSuccessToast({
        title: "Éxito",
        message: data.message,
      });
      setSend(true);
    } catch (error) {
      setSend(false);
      setLoading(false);
      showErrorToast({
        title: "Error",
        message: error.response.data.message,
      });
    }
  }

  return (
    <div className="reset-bg">
      <Container>
        <Col sm="12" md={{ size: 4, offset: 4 }} className="padd-top-8">
          <Card className="bg-color-segment">
            <CardBody>
              <span className="center padd-bot-3">
                <img src={LogoReset} alt="logo" className="img-fluid" />
              </span>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  Email: "",
                }}
                validationSchema={formSchema}
                onSubmit={onSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    <FormGroup row>
                      <Col sm="12" className="padd-bot-3">
                        <strong>
                          <label>
                            {send
                              ? "Verifique su correo y de clic en restablecer contraseña."
                              : "Ingrese la dirección de correo electrónico " +
                                "verificada de su cuenta de usuario y le enviaremos " +
                                "un enlace para restablecer la contraseña."}
                          </label>
                        </strong>
                        <FormGroup hidden={send}>
                          <Field
                            type="email"
                            name="Email"
                            className="input-form"
                            placeholder="Email"
                            required
                          />
                          {errors.Email && touched.Email ? (
                            <div>
                              <Badge className="badge-color">
                                {errors.Email}
                              </Badge>
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col sm="12" className="padd-bot-3" hidden={send}>
                        <FormGroup>
                          <Button
                            content="Enviar correo de restablecimiento"
                            color="purple"
                            type="submit"
                            fluid
                            loading={loading}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12" className="padd-bot-3">
                        <FormGroup>
                          <NavLink to={"/"}>
                            <Button
                              fluid
                              type="button"
                              basic
                              color="blue"
                              content="Regresar"
                            />
                          </NavLink>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </div>
  );
}
