import React, { useState, useEffect } from "react";
import { Row, Col, Card as ReactCard, CardBody, Container } from "reactstrap";
import Axios from "axios";
import { ROOT_URL } from "../constants/defaultValues";
import Header from "../components/Header";
import BreadcrumbTop from "../components/BreadcrumbTop";
import Chart from "react-apexcharts";
import { Label } from "semantic-ui-react";
import PlaceholderLoading from "../components/PlaceholderLoading";
import ReactApexChart from "react-apexcharts";
import { orderBy } from "lodash";
import { limitFloats } from "../helpers/utils";

export default function Reports({ logout, properties }) {
  const [loading, setLoading] = useState(true);
  const [LCSeries, setLCSeries] = useState(null);
  const [LCOptions, setLCOptions] = useState(null);
  const [LCSeriesSub, setLCSeriesSub] = useState(null);
  const [LCOptionsSub, setLCOptionsSub] = useState(null);
  const [pieSeries, setPieSeries] = useState(null);
  const [pieOptions, setPieOptions] = useState(null);
  const [treeSeries, setTreeSeries] = useState(null);
  const [treeOptions, setTreeOptions] = useState(null);
  const [radarSeries, setRadarSeries] = useState(null);
  const [radarOptions, setRadarOptions] = useState(null);
  const [radialSeries, setRadialSeries] = useState(null);
  const [radialOptions, setRadialOptions] = useState(null);

  const sections = [{ content: "Reportes", destination: "/null" }];

  useEffect(() => {
    async function getData() {
      try {
        const { data: result } = await Axios.get(`${ROOT_URL}/reports`);
        const con = result.usersPerYear.monthlyGrowthRate;
        const sus = result.subsPerYear.monthlyGrowthRate;
        const confirmed = result.usersConfirmedEmail.confirmed;
        const notConfirmed = result.usersConfirmedEmail.notConfirmed;
        const radialValue = limitFloats(
          (confirmed * 100) / (confirmed + notConfirmed)
        );

        setLCSeries([
          {
            name: "Usuarios",
            data: result.usersPerYear.values,
            type: "column",
          },
          {
            name: "Crecimiento",
            data: con.map((item) => parseInt(item)),
            type: "line",
          },
        ]);
        setLCOptions({
          chart: {
            id: "line",
          },
          stroke: {
            width: [0, 4],
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          xaxis: {
            categories: result.usersPerYear.users,
          },
          yaxis: [
            {
              title: {
                text: "Mes a mes",
              },
            },
            {
              opposite: true,
              title: {
                text: "Tasa de crecimiento",
              },
            },
          ],
        });

        setLCSeriesSub([
          {
            name: "Suscripciones",
            data: result.subsPerYear.values,
            type: "column",
          },
          {
            name: "Crecimiento",
            data: sus.map((item) => parseInt(item)),
            type: "line",
          },
        ]);
        setLCOptionsSub({
          chart: {
            id: "line",
          },
          stroke: {
            width: [0, 4],
          },
          colors: ["#775DD0", "#26E7A6"],
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
          },
          xaxis: {
            categories: result.subsPerYear.subs,
          },
          yaxis: [
            {
              title: {
                text: "Mes a mes",
              },
            },
            {
              opposite: true,
              title: {
                text: "Tasa de crecimiento",
              },
            },
          ],
        });

        setPieSeries(result.companies.values);
        setPieOptions({
          theme: {
            monochrome: {
              enabled: false,
            },
          },
          labels: result.companies.entities,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: "100%",
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        });

        const _data = result.usersPerRegion;
        const tmp = _data.map((x) => ({
          x: x.country,
          y: x.value,
        }));

        setTreeSeries([
          {
            data: orderBy(tmp, "y", "desc"),
          },
        ]);
        setTreeOptions({
          chart: {
            type: "treemap",
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          tooltip: {
            enabled: true,
            y: {
              show: true,
              formatter: (val) => `${val}`,
            },
          },
          plotOptions: {
            treemap: {
              distributed: true,
            },
          },
        });

        setRadarSeries([
          {
            name: "Clicks",
            data: result.records.values,
          },
        ]);
        setRadarOptions({
          chart: {
            type: "radar",
          },
          dataLabels: {
            enabled: true,
            background: {
              enabled: true,
              borderRadius: 2,
            },
          },
          plotOptions: {
            radar: {
              size: 100,
              polygons: {
                strokeColors: "#e9e9e9",
                fill: {
                  colors: ["#f8f8f8", "#fff"],
                },
              },
            },
          },
          fill: {
            opacity: 0.25,
            colors: ["#FF4560"],
          },
          colors: ["#FF4560"],
          markers: {
            size: 4,
            colors: ["#fff"],
            strokeColor: "#FF4560",
            strokeWidth: 2,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
          xaxis: {
            categories: result.records.courses,
          },
          yaxis: {
            tickAmount: 7,
            labels: {
              formatter: function (val, i) {
                if (i % 2 === 0) {
                  return val;
                } else {
                  return "";
                }
              },
            },
          },
        });

        setRadialSeries([radialValue]);
        setRadialOptions({
          chart: {
            height: 350,
            type: "radialBar",
          },
          colors: ["#20E647"],
          plotOptions: {
            radialBar: {
              hollow: {
                margin: 0,
                size: "80%",
                background: "#293450",
              },
              track: {
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  blur: 4,
                  opacity: 0.15,
                },
              },
              dataLabels: {
                name: {
                  offsetY: -10,
                  color: "#fff",
                  fontSize: "15px",
                },
                value: {
                  /*formatter: function (val) {
                    return parseInt(val);
                  },*/
                  color: "#fff",
                  fontSize: "35px",
                  show: true,
                },
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "vertical",
              gradientToColors: ["#87D4F9"],
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
          labels: ["Confirmaciones"],
        });

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    getData();
  }, []);

  return (
    <>
      <Header logout={logout} properties={properties} />
      <div className="padd-top-6 index-bg">
        <BreadcrumbTop sections={sections} />
        <Container>
          <Row>
            <Col sm="12" md="6" className="padd-bot-2">
              <ReactCard className={loading ? "trans" : null}>
                <CardBody>
                  <Row>
                    {LCSeries !== null && LCOptions !== null ? (
                      <>
                        <Label as="a" color="blue" ribbon>
                          Cantidad de usuarios por mes
                        </Label>
                        <Chart
                          options={LCOptions}
                          series={LCSeries}
                          width="100%"
                        />
                      </>
                    ) : (
                      <PlaceholderLoading />
                    )}
                  </Row>
                </CardBody>
              </ReactCard>
            </Col>
            <Col sm="12" md="6" className="padd-bot-2">
              <ReactCard className={loading ? "trans" : null}>
                <CardBody>
                  <Row>
                    {LCSeriesSub !== null && LCOptionsSub !== null ? (
                      <>
                        <Label as="a" color="blue" ribbon>
                          Cantidad de suscriptores por mes
                        </Label>
                        <Chart
                          options={LCOptionsSub}
                          series={LCSeriesSub}
                          width="100%"
                        />
                      </>
                    ) : (
                      <PlaceholderLoading />
                    )}
                  </Row>
                </CardBody>
              </ReactCard>
            </Col>
            <Col sm="12" className="padd-bot-2">
              <ReactCard className={loading ? "trans" : null}>
                <CardBody>
                  <Row>
                    {treeSeries !== null && treeOptions !== null ? (
                      <>
                        <Label as="a" color="blue" ribbon>
                          Cantidad de usuarios por región
                        </Label>
                        <ReactApexChart
                          options={treeOptions}
                          series={treeSeries}
                          type="treemap"
                          width="100%"
                        />
                      </>
                    ) : (
                      <PlaceholderLoading />
                    )}
                  </Row>
                </CardBody>
              </ReactCard>
            </Col>
            <Col sm="12" md="6" className="padd-bot-2">
              <ReactCard className={loading ? "trans" : null}>
                <CardBody>
                  <Row>
                    {radarOptions !== null && radarOptions !== null ? (
                      <>
                        <Label as="a" color="blue" ribbon>
                          Cursos mas vistos
                        </Label>
                        <ReactApexChart
                          options={radarOptions}
                          series={radarSeries}
                          type="radar"
                          width="100%"
                        />
                      </>
                    ) : (
                      <PlaceholderLoading />
                    )}
                  </Row>
                </CardBody>
              </ReactCard>
            </Col>
            <Col sm="12" md="6" className="padd-bot-2">
              <ReactCard className={loading ? "trans" : null}>
                <CardBody>
                  <Row>
                    {pieSeries !== null && pieOptions !== null ? (
                      <>
                        <Label as="a" color="blue" ribbon>
                          Cantidad de usuarios por empresa
                        </Label>
                        <ReactApexChart
                          options={pieOptions}
                          series={pieSeries}
                          type="pie"
                          width="100%"
                        />
                      </>
                    ) : (
                      <PlaceholderLoading />
                    )}
                  </Row>
                </CardBody>
              </ReactCard>
            </Col>
            <Col sm="12" md="6" className="padd-bot-2">
              <ReactCard className={loading ? "trans" : null}>
                <CardBody>
                  <Row>
                    {radialSeries !== null && radialOptions !== null ? (
                      <>
                        <Label as="a" color="blue" ribbon>
                          Cantidad de usuarios que validaron su cuenta
                        </Label>
                        <ReactApexChart
                          options={radialOptions}
                          series={radialSeries}
                          type="radialBar"
                          width="100%"
                          height={350}
                        />
                      </>
                    ) : (
                      <PlaceholderLoading />
                    )}
                  </Row>
                </CardBody>
              </ReactCard>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
