import React from "react";
import { Form } from "semantic-ui-react";

export default function InputForm({ handleInputChange, data, isBatch }) {
  return (
    <>
      <Form.Input
        fluid
        label="Días de suscripción"
        required
        type="number"
        placeholder="Días de suscripción"
        name="DiasSuscripcion"
        onChange={handleInputChange}
        min={1}
        max={365}
        value={data.DiasSuscripcion}
      />
      <Form.Input
        fluid
        label="Número de cupones"
        required
        type="number"
        placeholder="Número de cupones"
        name="NoCupones"
        onChange={handleInputChange}
        min={1}
        max={50000}
        value={data.NoCupones}
      />
      {isBatch && (
        <Form.Input
          fluid
          label="Precio"
          required
          type="number"
          placeholder="Precio"
          name="Precio"
          onChange={handleInputChange}
          min={1}
          max={5000}
          value={data.Precio}
        />
      )}
    </>
  );
}
