import React from "react";
import { Row, Col, Card as ReactCard, CardBody, Container } from "reactstrap";
import { Card, Image, Button, Icon } from "semantic-ui-react";
import Header from "../components/Header";
import BreadcrumbTop from "../components/BreadcrumbTop";
import UserCouponModal from "../containers/UserCouponModal";
import CompanyCouponModal from "../containers/CompanyCouponModal";
import BatchCouponModal from "../containers/BatchCouponModal";
import { coupons } from "../data/data";
import { NavLink } from "react-router-dom";


export default function Coupons({ logout, properties }) {
  const sections = [{ content: "Cupones", destination: "/null" }];

  return (
    <>
      <Header logout={logout} properties={properties} />
      <div className="padd-top-6 coupons-bg">
        <BreadcrumbTop sections={sections} />
        <Container>
          <Row>
            <Col sm="12">
              <ReactCard className="trans">
                <CardBody>
                  <Row>
                    <Card.Group itemsPerRow={4} stackable>
                      <Card color="purple">
                        <Image
                          src={coupons[0].Image}
                          wrapped
                          ui={false}
                          alt={coupons[0].Modal.Title}
                        />
                        <Card.Content extra>
                          <Card.Header>
                            <UserCouponModal props={coupons[0].Modal} />
                          </Card.Header>
                        </Card.Content>
                      </Card>
                      <Card color="purple">
                        <Image
                          src={coupons[1].Image}
                          wrapped
                          ui={false}
                          alt={coupons[1].Modal.Title}
                        />
                        <Card.Content extra>
                          <Card.Header>
                            <CompanyCouponModal props={coupons[1].Modal} />
                          </Card.Header>
                        </Card.Content>
                      </Card>
                      <Card color="purple">
                        <Image
                          src={coupons[2].Image}
                          wrapped
                          ui={false}
                          alt={coupons[2].Modal.Title}
                        />
                        <Card.Content extra>
                          <Card.Header>
                            <BatchCouponModal props={coupons[2].Modal} />
                          </Card.Header>
                        </Card.Content>
                      </Card>
                      <Card color="purple">
                        <Image
                          src={coupons[3].Image}
                          wrapped
                          ui={false}
                          alt={coupons[3].Modal.Title}
                        />
                        <Card.Content extra>
                          <Card.Header>
                            <NavLink to={"/coupon-list"}>
                              <Button
                                animated
                                color="purple"
                                size="small"
                                fluid
                              >
                                <Button.Content visible>
                                  {coupons[3].Modal.Title}
                                </Button.Content>
                                <Button.Content hidden>
                                  <Icon size="small" name="arrow right" />
                                </Button.Content>
                              </Button>
                            </NavLink>
                          </Card.Header>
                        </Card.Content>
                      </Card>
                    </Card.Group>
                  </Row>
                </CardBody>
              </ReactCard>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
