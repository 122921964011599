import React, { useReducer, useState, useEffect } from "react";
import {
  Button,
  Modal as MdlSmt,
  Icon,
  Form,
  Message,
  Divider,
} from "semantic-ui-react";
import Axios from "axios";
import { ROOT_URL } from "../constants/defaultValues";
import { courses } from "../data/data";
import { ToastContainer, toast } from "react-toastify";
import InputForm from "../components/InputForm";
import MultiCompanyForm from "../components/MultiCompanyForm";

function exampleReducer(state, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { open: true, dimmer: action.dimmer };
    case "CLOSE_MODAL":
      return { open: false };
    default:
      throw new Error();
  }
}

export default function CompanyCouponModal({ props, history }) {
  const [state, dispatch] = useReducer(exampleReducer, {
    open: false,
    dimmer: undefined,
  });
  const { open, dimmer } = state;
  const [loading, setLoading] = useState(true);
  const [infoCompany, setInfoCompany] = useState({
    DiasSuscripcion: 1,
    NoCupones: 1,
  });
  const initialState = {
    value: false,
    msg: "",
    existingRecord: false,
  };
  const [info, setInfo] = useState(initialState);
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [companies, setCompanies] = useState(null);
  const [company, setCompany] = useState({});
  const [copy, setCopy] = useState({});
  const [editCompany, setEditCompany] = useState(false);
  const [createCompany, setCreateCompany] = useState(false);
  const notify = (ms) =>
    toast.success(ms, {
      autoClose: 4000,
      theme: "dark",
    });

  useEffect(() => {
    async function getData() {
      try {
        const { data: companies } = await Axios.get(
          `${ROOT_URL}/companies/?view=names`
        );
        const data = [];
        companies &&
          companies.map((company) => {
            data.push({
              key: company.Nombre,
              text: company.Nombre,
              value: company.Nombre,
            });
          });

        const opt = [];
        courses.map((course) => {
          opt.push({
            label: course.Title,
            value: course.Title,
            disabled: !course.Available,
          });
        });

        setOptions(opt);
        setCompanies(data);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    getData();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    if (selected.length === 0) {
      setInfo({
        value: true,
        msg: "Seleccione al menos un curso",
        existingRecord: false,
      });
      return;
    }

    setLoading(true);

    const payload = {
      ...infoCompany,
      Cursos: selected.map((s) => s.value),
    };

    await Axios.post(`${ROOT_URL}/companies/`, payload)
      .then((response) => {
        setLoading(false);
        dispatch({ type: "CLOSE_MODAL" });
        setInfo(initialState);
        notify(response.data.message);
        setEditCompany(false);
        setCreateCompany(false);
        history.push("/coupon-list");
      })
      .catch((error) => {
        setInfo({
          value: true,
          msg: error,
          existingRecord: false,
        });
        setLoading(false);
        return;
      });
  }

  async function update(e) {
    e.preventDefault();

    if (selected.length === 0) {
      setInfo({
        value: true,
        msg: "Seleccione al menos un curso",
        existingRecord: false,
      });
      return;
    }

    const payload = {
      ...infoCompany,
      Cursos: selected.map((s) => s.value),
    };

    // iterate over keys
    Object.keys(payload)
      // remove non equals
      .filter((key) => payload[key] === copy[key])
      // delete all equals from originals
      .map((key) => delete payload[key] && delete copy[key]);

    // show results
    const _length = Object.keys(payload).length;

    if (_length === 0) {
      dispatch({ type: "CLOSE_MODAL" });
      notify("No se modificó ningún elemento");
      return;
    }

    setLoading(true);

    await Axios.patch(`${ROOT_URL}/companies/${company}`, payload)
      .then((response) => {
        setLoading(false);
        dispatch({ type: "CLOSE_MODAL" });
        setInfo(initialState);
        notify(response.data.message);
        setEditCompany(false);
        setCreateCompany(false);
        history.push("/coupon-list");
      })
      .catch((error) => {
        setInfo({
          value: true,
          msg: error,
          existingRecord: false,
        });
        setLoading(false);
        return;
      });
  }

  function handleInputChange(e) {
    //Bloquea la seccion de editar una empresa
    if (createCompany) {
      setEditCompany(false);
    } else {
      setEditCompany(true);
    }

    setInfoCompany({
      ...infoCompany,
      [e.target.name]: e.target.value,
    });
  }

  const handleCompanyChange = async (value) => {
    //Bloquea la seccion de crear una nueva empresa
    setCreateCompany(true);
    setCompany(value);

    await Axios.get(`${ROOT_URL}/companies/${value}`)
      .then((response) => {
        const payload = response.data;
        setInfoCompany(payload);
        setCopy(payload);

        const selected = payload.Cursos
          ? payload.Cursos.map((course) => ({ label: course, value: course }))
          : [];
        setSelected(selected);

        setInfo({
          ...info,
          value: false,
          existingRecord: true,
        });
      })
      .catch((error) => {
        setInfo({
          value: true,
          msg: error,
          existingRecord: false,
        });
      });
  };

  return (
    <div>
      <ToastContainer />
      <Button
        animated
        color="purple"
        size="small"
        fluid
        onClick={() => dispatch({ type: "OPEN_MODAL", dimmer: "blurring" })}
      >
        <Button.Content visible>{props.Title}</Button.Content>
        <Button.Content hidden>
          <Icon size="small" name="arrow right" />
        </Button.Content>
      </Button>
      <MdlSmt
        centered
        size="small"
        dimmer={dimmer}
        open={open}
        onClose={() => dispatch({ type: "CLOSE_MODAL" })}
        as={Form}
        onSubmit={(e) => handleSubmit(e)}
        loading={loading}
      >
        <MdlSmt.Header>
          {props.Title + "  "}
          {info.value && (
            <Message negative content={info.msg} compact size="tiny" />
          )}
        </MdlSmt.Header>
        <MdlSmt.Content>
          {!createCompany && (
            <>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  label="Nombre de la empresa"
                  required
                  type="text"
                  placeholder="Nombre"
                  name="Nombre"
                  onChange={handleInputChange}
                />
                {editCompany && (
                  <InputForm
                    handleInputChange={handleInputChange}
                    data={infoCompany}
                    isBatch={false}
                  />
                )}
              </Form.Group>
              {editCompany && (
                <MultiCompanyForm
                  options={options}
                  selected={selected}
                  setSelected={setSelected}
                />
              )}
            </>
          )}
          <Divider horizontal>O</Divider>
          {!editCompany && (
            <>
              <Form.Group widths="equal">
                <Form.Dropdown
                  fluid
                  label="Empresa"
                  placeholder="Seleccione una empresa."
                  required
                  onChange={(e, { value }) => {
                    handleCompanyChange(value);
                  }}
                  options={companies}
                  selection
                  value={company}
                />
                {createCompany && (
                  <InputForm
                    handleInputChange={handleInputChange}
                    data={infoCompany}
                    isBatch={false}
                  />
                )}
              </Form.Group>
              {createCompany && (
                <MultiCompanyForm
                  options={options}
                  selected={selected}
                  setSelected={setSelected}
                />
              )}
            </>
          )}
        </MdlSmt.Content>
        <MdlSmt.Actions>
          {editCompany && (
            <Button color="green" type="submit" loading={loading}>
              Crear
            </Button>
          )}
          {createCompany && !editCompany && (
            <Button
              color="green"
              type="button"
              loading={loading}
              onClick={(e) => update(e)}
            >
              Modificar
            </Button>
          )}
          <Button
            color="red"
            onClick={() => dispatch({ type: "CLOSE_MODAL" })}
            type="button"
          >
            Cancelar
          </Button>
        </MdlSmt.Actions>
      </MdlSmt>
    </div>
  );
}
