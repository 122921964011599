import { schengenCountries } from "../constants/defaultValues";

export default function getCurrencyAndPrices(country) {
  let currency;

  // Price ID
  let annualPrice;

  // Determina de qué pais viene para asignarle los priceID correspondientes
  if (country === "Mexico") {
    currency = "MXN";
  } else if (country === "United States" || country === "Canada") {
    currency = "USD";
  } else if (schengenCountries.includes(country)) {
    currency = "EUR";
  } else {
    currency = "ROW";
  }

  switch (currency) {
    case "MXN":
      annualPrice = "price_1OLSz9CVQ6RswNSeK4hpeCai";
      break;
    case "USD":
      annualPrice = "price_1OLSz9CVQ6RswNSe0kW0P7tf";
      break;
    case "EUR":
      annualPrice = "price_1OLSz9CVQ6RswNSeu9swWy7k";
      break;
    case "ROW":
      annualPrice = "price_1OLSz9CVQ6RswNSeTxgOROSv";
      break;
    default:
      break;
  }

  return annualPrice;
}

export const limitFloats = (number) => {
  return Math.round(number * 100) / 100;
};
