import React from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import { Breadcrumb } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function BreadcrumbTop({ sections }) {
  const { t } = useTranslation();

  return (
    <Container className="padd-bot-1">
      <Row>
        <Col sm="12">
          <Card className="trans">
            <CardBody>
              <Row>
                <Breadcrumb size="small">
                  <Link to="/">
                    <Breadcrumb.Section link>{t("inicio")}</Breadcrumb.Section>
                  </Link>
                  <Breadcrumb.Divider icon="right angle" />
                  {sections.map((section, i) =>
                    i !== sections.length - 1 ? (
                      <Link to={section.destination}>
                        <Breadcrumb.Section link key={i}>
                          {section.content}
                        </Breadcrumb.Section>
                        <Breadcrumb.Divider icon="right angle" />
                      </Link>
                    ) : (
                      <Breadcrumb.Section active key={i}>
                        {section.content}
                      </Breadcrumb.Section>
                    )
                  )}
                </Breadcrumb>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
