export const projects = [
  {
    Project: 1,
    Course: "primeros-pasos",
    Title: "Introducción a Yarvix",
    TitleCA: "Introducció a Yarvix",
    Url: "dicc1oluegyg5",
  },
  {
    Project: 1,
    Course: "robotica-y-programacion",
    Title: "Activando señales",
    InteractivePractice: "d1a23456iyuh1b",
    DigitalLesson: "djzxgdhpvuq1f",
    ApplicationChallenge: "Mision_1_Robotica.pdf",
    Available: true,
    Color: "red",
    Description:
      "Introdúcete en el mundo de la robótica y programación haciendo uso de componentes electrónicos, robots y software. Aprenderás a programar una secuencia de luces y crearás sistemas de control lumínico.",
    TitleCA: "Activant senyals",
    DescriptionCA:
      "Introdueix-te al món de la robòtica i la programació fent ús de components electrònics, robots i programari. Aprendràs a programar una seqüència de llums i crearàs sistemes de control lumínic.",
  },
  {
    Project: 2,
    Course: "robotica-y-programacion",
    Title: "Interactuando con el exterior",
    InteractivePractice: "d3msp1vu2ur0ds",
    DigitalLesson: "d3ru70m3rni7dl",
    ApplicationChallenge: "Mision_2_Robotica.pdf",
    Available: true,
    Color: "orange",
    Description:
      "Interactúa con tu robot y sus botones para controlar luces y sonidos. Aprenderás funciones de programación en Arduino.",
    TitleCA: "Interactuant amb l'exterior",
    DescriptionCA:
      "Interactua amb el teu robot i els seus botons per controlar llums i sons. Aprendràs funcions de programació en Arduino",
  },
  {
    Project: 3,
    Course: "robotica-y-programacion",
    Title: "SOS Rocket Robot",
    InteractivePractice: "dqtxrq3nup3jr",
    DigitalLesson: "dy0tqd6f330pr",
    ApplicationChallenge: "Mision_3_Robotica.pdf",
    Available: true,
    Color: "yellow",
    Description:
      "¿Qué es un robot móvil y cómo se clasifican? Aprenderás a programar mensajes a través del monitor serial de Arduino y proyectar mensajes por medio de la pantalla LCD incorporada en tu robot.",
    TitleCA: "SOS Robot Cohet",
    DescriptionCA:
      "Què és un robot mòbil i com es classifiquen? Aprendràs a programar missatges a través del monitor sèrie d'Arduino i a projectar missatges mitjançant la pantalla LCD incorporada al teu robo",
  },
  {
    Project: 4,
    Course: "robotica-y-programacion",
    Title: "Activa los motores",
    InteractivePractice: "d17jke9taoqu00",
    DigitalLesson: "d3ozesiqnwpu2p",
    ApplicationChallenge: "Mision_4_Robotica.pdf",
    Available: true,
    Color: "olive",
    Description:
      "Crearás una secuencia de movimiento para establecer una ruta que le permitirá a tu Rocket Robot desplazarse. ¿Qué es un sensor y qué importancia tiene en un sistema robótico?",
    TitleCA: "Activa els motors",
    DescriptionCA:
      "Interactua amb el teu robot i els seus botons per controlar llums i sons. Aprendràs funcions de programació en Arduino",
  },
  {
    Project: 5,
    Course: "robotica-y-programacion",
    Title: "El rastro de la luz",
    InteractivePractice: "d3vdfgbnqaz4mc",
    DigitalLesson: "d38pkymq3n9vgo",
    ApplicationChallenge: "Mision_5_Robotica.pdf",
    Available: true,
    Color: "green",
    Description:
      "Veamos cómo funcionan los sensores de luz y reconozcamos algunos conceptos de programación que nos permiten generar autonomía en un sistema robótico de acuerdo a las señales recibidas del exterior por medio de sensores. ",
    TitleCA: "El rastreig de la llum",
    DescriptionCA:
      "Veurem com funcionen els sensors de llum i reconeixerem alguns conceptes de programació que ens permeten generar autonomia en un sistema robòtic d'acord amb les senyals rebudes de l'exterior per mitjà de sensors.",
  },
  {
    Project: 6,
    Course: "robotica-y-programacion",
    Title: "¿Qué tan cerca estamos?",
    InteractivePractice: "d2lhrk12j6po2k",
    DigitalLesson: "d2uqpga6i22ju4",
    ApplicationChallenge: "Mision_6_Robotica.pdf",
    Available: true,
    Color: "teal",
    Description:
      "¿Nuestro Rocket Robot puede ver? Descubramos cómo el sensor de ultrasonidos incorporado le permite identificar obstáculos en su camino. Es momento de aprender sobre los diferentes tipos de sensores que podemos encontrar en un sistema robótico y cómo en la actualidad estos les permiten a los robots tener una mayor independencia en el desarrollo de sus labores.",
    TitleCA: "Què tan a prop estem?",
    DescriptionCA:
      "¿El nostre Rocket Robot pot veure? Descobrim com el sensor d'ultrasons incorporat li permet identificar obstacles en el seu camí. És moment d'aprendre sobre els diferents tipus de sensors que podem trobar en un sistema robòtic i com en l'actualitat aquests els permeten als robots tenir una major independència en el desenvolupament de les seves tasques.",
  },
  {
    Project: 7,
    Course: "robotica-y-programacion",
    Title: "Archivos",
    Available: false,
    Color: "blue",
    Description:
      "Descarga el material visto en los proyectos anteriores, código y diagramas",
    Download1: "multi-pro.s3.us-east-2.amazonaws.com/miprimerrobot/Codigos.zip",
    Download2:
      "multi-pro.s3.us-east-2.amazonaws.com/miprimerrobot/Diagramas.zip",
    TitleCA: "Fitxers",
    DescriptionCA:
      "Descarrega el material vist als projectes anteriors, codi font i diagrames",
  },
  {
    Project: 1,
    Course: "bionica",
    Title: "La maestra naturaleza",
    InteractivePractice: "d2tfapu60mnt93",
    DigitalLesson: "d2n2s0zt0ki1ci",
    ApplicationChallenge: "BIO_RA_01.pdf",
    Available: true,
    Color: "blue",
    Description:
      "Conoce como la biónica es esa disciplina que combina la ingeniería y el diseño inspirados en principios y creaciones biológicas, descubre los principios de funcionamiento de algunos dispositivos de uso cotidiano o actual y que fueron inspirados en la naturaleza.",
    TitleCA: "La mestra natura",
    DescriptionCA:
      "Coneix com la biónica és aquella disciplina que combina l'enginyeria i el disseny inspirats en principis i creacions biològiques, descobreix els principis de funcionament d'alguns dispositius d'ús quotidà o actuals que van ser inspirats en la natura.",
  },
  {
    Project: 2,
    Course: "bionica",
    Title: "Taller de partes humanas",
    InteractivePractice: "d16v1gaky5s1ya",
    DigitalLesson: "daufgkryimrpg",
    ApplicationChallenge: "BIO_RA_02.pdf",
    Available: true,
    Color: "violet",
    Description:
      "Reconocerás la importancia de la biónica en la creación de prótesis o partes del cuerpo del ser humano. Conocerás la evolución y avances de la biónica en el mejoramiento de la calidad de vida de muchas personas.",
    TitleCA: "Taller de parts humanes",
    DescriptionCA:
      "Reconeixeràs la importància de la biónica en la creació de pròtesis o parts del cos del ser humà. Coneixeràs l'evolució i avenços de la biónica en la millora de la qualitat de vida de moltes persones.",
  },
  {
    Project: 3,
    Course: "bionica",
    Title: "El poder de la mente",
    InteractivePractice: "d1jlwjzo1ntbzd",
    DigitalLesson: "d3054hs5lynvus",
    ApplicationChallenge: "BIO_RA_03.pdf",
    Available: true,
    Color: "purple",
    Description:
      "Conoce como la biónica es esa disciplina que combina la ingeniería y el diseño inspirados en principios y creaciones biológicas, descubre los principios de funcionamiento de algunos dispositivos de uso cotidiano o actual y que fueron inspirados en la naturaleza.",
    TitleCA: "El poder de la ment",
    DescriptionCA:
      "Coneix com la biónica és aquella disciplina que combina l'enginyeria i el disseny inspirats en principis i creacions biològiques, descobreix els principis de funcionament d'alguns dispositius d'ús quotidiana o actuals que van ser inspirats en la natura.",
  },
  {
    Project: 1,
    Course: "domotica-y-iot",
    Title: "Una casa automática",
    InteractivePractice: "d2i8hej1bid8gi",
    DigitalLesson: "d3ph2wolj0h4s6",
    ApplicationChallenge: "DOM_RA_01.pdf",
    Available: true,
    Color: "pink",
    Description:
      "Conoce el momento histórico en el que surgió la primera casa automática, dando inicio al surgimiento de la Domótica, realiza un primer acercamiento al tema usando tu equipo móvil y programa circuitos simples haciendo uso del software Tinkercad.",
    TitleCA: "Una casa automàtica ",
    DescriptionCA:
      "Coneix el moment històric en què va sorgir la primera casa automàtica, donant inici a l'aparició de la Domòtica, realitza un primer acostament al tema fent servir el teu equip mòbil i programa circuits simples fent ús del software Tinkercad.",
  },
  {
    Project: 2,
    Course: "domotica-y-iot",
    Title: "Identificando y controlando variables",
    InteractivePractice: "d3vwhiwzuc9a2e",
    DigitalLesson: "d29086ezr084t8",
    ApplicationChallenge: "DOM_RA_02.pdf",
    Available: true,
    Color: "brow",
    Description:
      "Reconoce las distintas variables que existen en un hogar y como puedes controlar cada una de ellas para que tu casa sea un espacio único, ajustado a tus gustos, intereses y necesidades.",
    TitleCA: "Identificant i controlant variables ",
    DescriptionCA:
      "Reconeix les diferents variables que existeixen en un habitatge i com pots controlar cadascuna d'elles perquè la teva casa sigui un espai únic, ajustat als teus gustos, interessos i necessitats.",
  },
  {
    Project: 3,
    Course: "domotica-y-iot",
    Title: "Elementos que conforman un circuito domótico",
    InteractivePractice: "d36w2n42jam32s",
    DigitalLesson: "d1hni7xnzm2s53",
    ApplicationChallenge: "DOM_RA_03.pdf",
    Available: true,
    Color: "grey",
    Description:
      "Conoce los distintos elementos que debe tener un proyecto de domótica, los software y aplicaciones disponibles, así como algunos productos disponibles en el mercado (sensores y actuadores) que harán de tu Smart Home una realidad.",
    TitleCA: "Elements que conformen un circuit domòtic ",
    DescriptionCA:
      "Coneix els diferents elements que ha de tenir un projecte de domòtica, els programaris i aplicacions disponibles, així com alguns productes disponibles al mercat (sensors i actuadors) que faran realitat la teva Smart Home.",
  },
  {
    Project: 1,
    Course: "energias-renovables",
    Title: "Introducción a las energías renovables",
    InteractivePractice: "d2qcshn0t7ily5",
    DigitalLesson: "d3ten22w355k14",
    ApplicationChallenge: "ER_RA_01_Cuanta_agua_consumes.pdf",
    Available: true,
    Color: "black",
    Description:
      "Conoce el impacto negativo del uso de combustibles fósiles como fuente de energía, la huella de carbono de los seres humanos y los efectos del cambio climático.",
    TitleCA: "Introducció a les energies renovables ",
    DescriptionCA: "Coneix l'impacte negatiu de l'ús de combustibles fòssils com a font d'energia, la petjada de carboni dels éssers humans i els efectes del canvi climàtic.",
  },
  {
    Project: 2,
    Course: "energias-renovables",
    Title: "Tipos de energías renovables",
    InteractivePractice: "d18cb6igog7u4f",
    DigitalLesson: "dze1qarcps97g",
    ApplicationChallenge: "ER_RA_02_Tu_propio_generador.pdf",
    Available: true,
    Color: "red",
    Description:
      "Aprende sobre las distintas clases de energías renovables que existen, que tipos de recursos naturales usan y cual es su impacto positivo en el medio ambiente.",
    TitleCA: "Tipus d'energies renovables",
    DescriptionCA: "Aprèn sobre les diferents classes d'energies renovables que existeixen, quins tipus de recursos naturals utilitzen i quin és el seu impacte positiu en el medi ambient.",
  },
  {
    Project: 3,
    Course: "energias-renovables",
    Title: "Aprende a realizar desde casa tus sistemas con energías renovables",
    InteractivePractice: "d27uaqs7gm1pq3",
    DigitalLesson: "dpj2bxelfwlgj",
    ApplicationChallenge: "ER_RA_03_Una_batería_con_una_patata.pdf",
    Available: true,
    Color: "orange",
    Description:
      "Aprende a hacer un circuito de luz que se activa y desactiva con la presencia de personas, es decir, eficiencia energética y aprender a realizar un generador eólico casero.",
    TitleCA: " Aprèn a realitzar des de casa teva els teus sistemes amb energies renovables.",
    DescriptionCA: "Aprèn a fer un circuit de llum que s'activa i es desactiva amb la presència de persones, és a dir, eficiència energètica i aprèn a realitzar un generador eòlic casolà.",
  },
  {
    Project: 1,
    Course: "scratch",
    Title: "Satélites en movimiento",
    InteractivePractice: "d2mds9ikorkott",
    DigitalLesson: "d1i7uye0t5555x",
    ApplicationChallenge: "Reto_de_Aplicacion_Satelites.pdf",
    Available: true,
    Color: "yellow",
    Description:
      "Introducción a Scratch y su interfaz, cómo crear personajes, diálogos y disfraces, además del movimiento con de estos, su tamaño y los fondos. Desarrollarás un programa que permitirá mover un satélite con teclado y ratón.",
    TitleCA: "Satèl·lits en moviment",
    DescriptionCA: "Introducció a Scratch i la seva interfície, com crear personatges, diàlegs i disfresses, així com el moviment d'aquests, la seva mida i els fons. Desenvoluparàs un programa que permetrà moure un satèl·lit amb el teclat i el ratolí.",
  },
  {
    Project: 2,
    Course: "scratch",
    Title: "Desarrolla el Juego ¡No vayas a chocar!",
    InteractivePractice: "d1gj70mfjl37u1",
    DigitalLesson: "dm9i4auzuzjem",
    ApplicationChallenge: "Reto_de_Aplicacio_No_TeVayas_a_Chocar.pdf",
    Available: true,
    Color: "olive",
    Description:
      "Programarás un juego que permita mostrar una sonda espacial que pueda moverse en el espacio mientras estudia datos de cometas.",
    TitleCA: "Desenvolupa el Joc No xoquis!",
    DescriptionCA: "Programaràs un joc que permeti mostrar una sonda espacial que pugui moure's en l'espai mentre estudia dades de cometes.",
  },
  {
    Project: 3,
    Course: "scratch",
    Title: "Desarrolla el Juego (Dibujando constelaciones)",
    InteractivePractice: "d3ozeh4f0bn5d7",
    DigitalLesson: "d30m38wryu4azw",
    ApplicationChallenge: "Reto_de_Aplicacio_Constelaciones.pdf",
    Available: true,
    Color: "green",
    Description:
      "Programarás un juego utilizando la extensión de lápiz, está nos servirá para dibujar constelaciones, además incorporarás variables como tiempos.",
    TitleCA: "Desenvolupa el Joc (Dibuixant constel·lacions)",
    DescriptionCA: "Programaràs un joc utilitzant l'extensió de llapis, que ens servirà per dibuixar constel·lacions. A més, incorporaràs variables com ara temps.",
  },
  {
    Project: 1,
    Course: "minecraft",
    Title: "Un juego de entorno libre",
    InteractivePractice: "d155lr946lzqvh",
    DigitalLesson: "dgar1j96mfw9s",
    ApplicationChallenge: "MCT_RA_01.pdf",
    Available: true,
    Color: "teal",
    Description:
      "Aprenderás la historia y evolución de los juegos de entorno libre, su evolución desde los juegos de bloques, hasta su ingreso al mundo virtual. Conocerás los requisitos para descargar la versión Minecraft Education y tendrás un primer acercamiento con el juego.",
    TitleCA: "Un joc d'entorn lliure ",
    DescriptionCA: "Aprendràs la història i evolució dels jocs d'entorn lliure, la seva evolució des dels jocs de blocs fins a la seva entrada al món virtual. Coneixeràs els requisits per descarregar la versió Minecraft Education i tindràs un primer acostament amb el joc.",
  },
  {
    Project: 2,
    Course: "minecraft",
    Title: "Explorando movimientos, acciones y construcciones.",
    InteractivePractice: "ddg1bbiazuc0g",
    DigitalLesson: "d2ymai6q8wuay7",
    ApplicationChallenge: "MCT_RA_02.pdf",
    Available: true,
    Color: "blue",
    Description:
      "Navega por la aplicación y conoce las distintas posibilidades que hay en términos de movimientos, acciones, construcciones, armas, etc. Ingresa al menú de misiones y conoce de que se trata cada una de ellas.",
    TitleCA: "Explorant moviments, accions i construccions ",
    DescriptionCA: "Navega per l'aplicació i coneix les diferents possibilitats que hi ha en termes de moviments, accions, construccions, armes, etc. Entra al menú de missions i coneix de què es tracta cadascuna d'elles.",
  },
  {
    Project: 3,
    Course: "minecraft",
    Title: "La hora del código",
    InteractivePractice: "d32dm1d9d5bckm",
    DigitalLesson: "d2vrlwnqps8co6",
    ApplicationChallenge: "MCT_RA_03.pdf",
    Available: true,
    Color: "violet",
    Description:
      "Aprenderás a programar a “Agent” el agente robótico que realizará en el juego algunas tareas por ti permitiendo superar misiones más complejas, al mismo tiempo que aprendes y avanzas en el mundo de la programación por bloques.",
    TitleCA: "La hora del codi",
    DescriptionCA: "Aprendràs a programar Agent, l'agent robòtic que realitzarà en el joc algunes tasques per tu, permetent-te superar missions més complexes, al mateix temps que aprens i avances en el món de la programació per blocs.",
  },
  {
    Project: 1,
    Course: "robotica-basica",
    Title: "Robótica Báscia",
    Url: "1_Introducci%C3%B3n.mp4",
    Description: "",
  },
];

export const courses = [
  {
    Number: 1,
    Title: "Robotica",
    Available: true,
  },
  {
    Number: 2,
    Title: "Bionica",
    Available: true,
  },
  {
    Number: 3,
    Title: "Domotica",
    Available: true,
  },
  {
    Number: 4,
    Title: "Energias Renovables",
    Available: true,
  },
  {
    Number: 5,
    Title: "Scratch",
    Available: true,
  },
  {
    Number: 6,
    Title: "Minecraft",
    Available: true,
  },
  {
    Number: 7,
    Title: "Robotica Basica",
    Available: false,
  },
];

export const carouselImages = [
  {
    Image: require("../images/primeros-pasos.png"),
    Icon: "hand point up",
    Course: "primeros-pasos",
    Color: "red-secondary",
    Description:
      "Antes de iniciar cualquier curso, te recomendamos que inicies con este tutorial que ponemos a tu disposición para que tengas una mejor uta de aprendizaje. ¡Gracias por entrar a Yarvix y mucho éxito!",
    Content: "Primeros Pasos",
    DescriptionCA:
      "Abans de començar qualsevol curs, et recomanem que comencis amb aquest tutorial que posem a la teva disposició perquè tinguis una millor ruta d'aprenentatge. Gràcies per entrar a Yarvix i molta sort!",
    ContentCA: "Primers Passos",
    DataColor: "red",
  },
  {
    Image: require("../images/energias-renovables.png"),
    Icon: "recycle",
    Content: "Energias Renovables",
    Course: "energias-renovables",
    Description:
      "¡Explorar el espacio requiere de mucha energía! Afortunadamente, la transición energética hacia energías más limpias es posible y el espacio puede tener secretos que nos ayuden.",
    DescriptionCA:
      "Explorar l'espai requereix molta energia! Afortunadament, la transició energètica cap a energies més netes és possible i l'espai pot tenir secrets que ens ajudin.",
    ContentCA: "Energies Renovables",
    Color: "green-secondary",
    DataColor: "green",
  },
  {
    Image: require("../images/scratch.png"),
    Icon: "lightbulb",
    Content: "Scratch",
    Course: "scratch",
    Description:
      "Aprender programación es una necesidad para las misiones y nada mejor que hacerlo mientras jugamos, por eso con este curso de Scratch conocerás las bases del pensamiento computacional.",
    DescriptionCA:
      "Aprendre programació és una necessitat per a les missions i res millor que fer-ho mentre juguem, per això amb aquest curs de Scratch coneixeràs les bases del pensament computacional.",
    ContentCA: "Scratch",
    Color: "teal-secondary",
    DataColor: "teal",
  },
  {
    Image: require("../images/robotica-y-programacion-con-arduino.png"),
    Icon: "microchip",
    Content: "Robótica",
    Course: "robotica-y-programacion",
    Description:
      "¡Los robots están en todas partes! En el espacio necesitaremos robots que nos apoyen. Aprende las bases de la robótica, y dale vida a tu primer robot programándolo.",
    DescriptionCA:
      "Els robots estan per tot arreu! A l'espai necessitarem robots que ens donin suport. Aprèn les bases de la robòtica, i dóna vida al teu primer robot programant-lo.",
    ContentCA: "Robòtica Avançada",
    Color: "orange-secondary",
    DataColor: "orange",
  },
  {
    Image: require("../images/minecraft.png"),
    Icon: "diamond",
    Content: "Minecraft",
    Course: "minecraft",
    Description:
      "¡El mundo está en la web! En esta misión tus alumnos aprenderán a desarrollar, aprendiendo las bases de la programación. ¡Al internet desde cualquier lugar de la galaxia!",
    DescriptionCA: "El món és a la web! En aquesta missió, els teus alumnes aprendran a desenvolupar, aprenent les bases de la programació. A internet des de qualsevol lloc de la galàxia!",
    ContentCA: "Minecraft",
    Color: "blue-secondary",
    DataColor: "blue",
    Class: 6,
    Language: "c#, arduino",
  },
  {
    Image: require("../images/bionica.png"),
    Icon: "hand spock",
    Content: "Biónica",
    Course: "bionica",
    Description:
      "La biónica ha permitido el avance de la tecnología para apoyar al ser humano en diferentes dimensiones. Haz que tus alumnos, descubran los sistemas naturales en fuente de ideas para sus proyectos.",
    DescriptionCA: "La biònica ha permès avançar la tecnologia per donar suport a l'ésser humà en diferents dimensions. Feu que els vostres alumnes descobreixin els sistemes naturals com a  font d'idees per als projectes.",
    ContentCA: "Biónica",
    Color: "yellow-secondary",
    DataColor: "yellow",
  },
  {
    Image: require("../images/domotica-y-iot.png"),
    Icon: "home",
    Content: "Domótica y IoT",
    Course: "domotica-y-iot",
    Description:
      "¿Podremos habitar otros planetas? ¿Cómo serán nuestras casas allí? Descubramos cómo a través de la tecnología se automatizan los hogares, controlando luces, sistemas de seguridad.",
    DescriptionCA:
      "Podrem habitar altres planetes? Com seran les nostres cases allà? Descobrim com a través de la tecnologia s'automatitzen les llars, controlant llums, sistemes de seguretat.",
    ContentCA: "Domòtica i IoT",
    Color: "olive-secondary",
    DataColor: "olive",
  },
];

export const coupons = [
  {
    Image: require("../images/entidad.jpg"),
    Modal: {
      Title: "Asignar a un usuario",
    },
  },
  {
    Image: require("../images/empresa.jpg"),
    Modal: {
      Title: "Crear o editar una empresa",
    },
  },
  {
    Image: require("../images/batch-cupones.jpg"),
    Modal: {
      Title: "Crear o editar un batch",
    },
  },
  {
    Image: require("../images/lista-cupones.jpg"),
    Modal: {
      Title: "Ver cupones",
    },
  },
];

export const dowloads = [
  {
    Id: 0,
    Link: "downloads.arduino.cc/arduino-1.8.19-windows.exe",
    Img: require("../images/windows.jpg"),
    Title: "Arduino para Windows",
    TitleCA: "Arduino per a Windows",
    Color: "blue",
  },
  {
    Id: 1,
    Link: "downloads.arduino.cc/arduino-1.8.19-linux64.tar.xz",
    Img: require("../images/linux.jpg"),
    Title: "Arduino para Linux",
    TitleCA: "Arduino per a Windows",
    Color: "violet",
  },
  {
    Id: 2,
    Link: "downloads.arduino.cc/arduino-1.8.19-macosx.zip",
    Img: require("../images/mac.jpg"),
    Title: "Arduino para Mac OS",
    TitleCA: "Arduino per a Windows",
    Color: "black",
  },
  {
    Id: 3,
    Link: "multi-pro.s3.us-east-2.amazonaws.com/miprimerrobot/DRIVER_ROCKET_ROBOT.rar",
    Img: require("../images/driver.jpg"),
    Title: "Driver de RocketRobot",
    TitleCA: "Driver de RocketRobot",
    Color: "red",
  },
];
