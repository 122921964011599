import React from "react";
import { Loader } from "semantic-ui-react";

export default function Loading() {
  return (
    <div
      style={{
        paddingTop: "250px",
        boxSizing: "content-box",
      }}
      containerstyle={{
        position: "relative",
        overflow: "visible",
      }}
    >
      <Loader active inline="centered" size="massive" />
    </div>
  );
}
