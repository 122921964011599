import React from "react";
import { Placeholder } from "semantic-ui-react";

export default function PlaceholderLoading() {
  return (
    <Placeholder fluid>
      <Placeholder.Line length="full" />
      <br />
      <Placeholder.Line length="full" />
      <br />
      <Placeholder.Line length="full" />
      <br />
      <Placeholder.Line length="full" />
      <br />
      <Placeholder.Line length="full" />
      <br />
      <Placeholder.Line length="full" />
      <br />
      <Placeholder.Line length="full" />
      <br />
    </Placeholder>
  );
}