import React from "react";
import { Menu, Dropdown, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Header({ logout, properties }) {
  const role = properties.role;
  const { t, i18n } = useTranslation();

  const changeLanguageTrigger = (e) => {
    const languageCode = e.target.value;
    i18n.changeLanguage(languageCode);
  };

  return (
    <Menu
      borderless
      fluid
      inverted
      fixed="top"
      size="small"
      color="black"
      compact
    >
      <Link to="/">
        <Menu.Item>
          <img
            alt="logo"
            src= {require("../images/logo_oscuro.png")}
          />
        </Menu.Item>
      </Link>
      <Menu.Menu position="right">
        <Dropdown item icon="setting" simple>
          <Dropdown.Menu>
            <Dropdown.Item onClick={logout} icon="sign-out" text={t("salir")} />
            <Link to="/profile">
              <Dropdown.Item
                icon="user circle"
                text={t("perfil")}
                className="no-decoration"
              />
            </Link>
            <Link to="/subscription">
              <Dropdown.Item
                icon="ticket"
                text={t("cupon")}
                className="no-decoration"
              />
            </Link>
            {(role === "SisAdmin" || role === "Admin") && (
              <>
                <Link to="/users">
                  <Dropdown.Item
                    icon="users"
                    text={t("usuarios")}
                    className="no-decoration"
                  />
                </Link>
                <Link to="/reports">
                  <Dropdown.Item
                    icon="chart line"
                    text={t("reportes")}
                    className="no-decoration"
                  />
                </Link>
              </>
            )}
            {role === "SisAdmin" && (
              <Link to="/coupons">
                <Dropdown.Item
                  icon="ticket alternate"
                  text={t("cupones")}
                  className="no-decoration"
                />
              </Link>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
}
