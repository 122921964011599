import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Axios from "axios";
import {
  setToken,
  deleteToken,
  getToken,
  initAxiosInterceptors,
} from "./helpers/auth-helpers";
import { ROOT_URL } from "./constants/defaultValues";
import { ToastContainer, toast } from "react-toastify";
import Login from "./views/Login";
import Loading from "./components/Loading";
import Dashboard from "./views/Dashboard";
import Practices from "./views/Practices";
import SignUp from "./views/SignUp";
import Profile from "./views/Profile";
import Users from "./views/Users";
import Coupons from "./views/Coupons";
import CouponList from "./views/CouponList";
import Subscription from "./views/Subscription";
import Courses from "./views/Courses";
import User from "./views/User";
import Contact from "./views/Contact";
import Reports from "./views/Reports";
import ForgotPassword from "./views/ForgotPassword";
import PasswordReset from "./views/PasswordReset";
import Verify from "./views/Verify";

/*Intercepta todas las llamadas y coloca un encabezado 
con el token*/
initAxiosInterceptors();

export default function App() {
  const [user, setUser] = useState(null);
  const [properties, setProps] = useState({
    role: "Usuario",
    suscription: true,
  });
  const [loadingUser, setLoadingUser] = useState(true);

  const notify = (ms) =>
    toast.success(ms, {
      autoClose: 4000,
    });

  //Verifica si existe un usuario autenticado
  useEffect(() => {
    async function loadUser() {
      if (!getToken()) {
        setLoadingUser(false);
        return;
      }

      try {
        const { data } = await Axios.get(`${ROOT_URL}/auth/whoami`);
        setUser(data.user);
        setProps(data.props);
        setLoadingUser(false);
      } catch (error) {
        console.log(error);
      }
    }
    loadUser();
  }, []);

  //Recibe email y password de la vista login y manda un request
  //al recurso
  async function login(Email, Contrasena) {
    const { data } = await Axios.post(`${ROOT_URL}/auth/login`, {
      Email,
      Contrasena,
    });
    setUser(data.user);
    setProps(data.props);
    setToken(data.token);
    notify(data.message);
  }

  //Recibe un objeto de la vista signup y manda el request
  //al recurso
  async function signup(user) {
    const { data } = await Axios.post(`${ROOT_URL}/auth/signup`, user);
    setUser(data.user);
    setProps(data.props);
    setToken(data.token);
    notify(data.message);
  }

  function logout() {
    setUser(null);
    setProps({
      role: "Usuario",
      suscription: true,
    });
    deleteToken();
  }

  /*Si se está cargando un usuario, se muestra el 
  componente loading*/
  if (loadingUser) {
    return <Loading />;
  }

  return (
    <Router>
      {/*Se mandan props y métodos a los componetes/vistas */}
      <ToastContainer />
      {!properties.verify_email && user ? (
        <Switch>
          <Route
            path={[`/verify/confirm=:confirm&token=:token`, "/"]}
            render={(props) => (
              <Verify {...props} logout={logout} properties={properties} />
            )}
            default
          />
        </Switch>
      ) : user ? (
        <LoginRoutes user={user} logout={logout} properties={properties} />
      ) : (
        <LogoutRoutes login={login} signup={signup} />
      )}
    </Router>
  );
}

/*Maneja todas las vistas a las que pueden 
tener acceso los usuarios registrados*/
function LoginRoutes({ user, logout, properties }) {
  return (
    <Switch>
      <Route
        path={`/practices/type=:type&course=:course&ref=:ref`}
        render={(props) => (
          <Practices {...props} logout={logout} properties={properties} />
        )}
      />
      <Route
        path={`/courses/course=:course`}
        render={(props) => (
          <Courses {...props} logout={logout} properties={properties} />
        )}
      />
      <Route
        path={`/user/:userid`}
        render={(props) => (
          <User {...props} logout={logout} properties={properties} />
        )}
      />
      <Route
        path={`/profile`}
        render={(props) => (
          <Profile
            {...props}
            user={user}
            logout={logout}
            properties={properties}
          />
        )}
      />
      <Route
        path={`/contact`}
        render={(props) => (
          <Contact
            {...props}
            user={user}
            logout={logout}
            properties={properties}
          />
        )}
      />
      <Route
        path={`/reports`}
        render={(props) => (
          <Reports
            {...props}
            user={user}
            logout={logout}
            properties={properties}
          />
        )}
      />
      <Route
        path={[
          "/subscription",
          "/subscription?success=:success&session_id=:session_id",
          "/subscription?canceled=true",
        ]}
        render={(props) => (
          <Subscription
            {...props}
            user={user}
            logout={logout}
            properties={properties}
          />
        )}
      />
      <Route
        path={`/users`}
        render={(props) => (
          <Users {...props} logout={logout} properties={properties} />
        )}
      />
      <Route
        path={`/coupons`}
        render={(props) => (
          <Coupons {...props} logout={logout} properties={properties} />
        )}
      />
      <Route
        path={`/coupon-list`}
        render={(props) => (
          <CouponList {...props} logout={logout} properties={properties} />
        )}
      />
      <Route
        path="/"
        render={(props) => (
          <Dashboard
            {...props}
            logout={logout}
            properties={properties}
            user={user}
          />
        )}
        default
      />
    </Switch>
  );
}

/*Maneja todas las vistas a las que pueden 
tener acceso los usuarios que no están registrados */
function LogoutRoutes({ login, signup }) {
  return (
    <Switch>
      <Route
        path="/signup/reference=:reference"
        render={(props) => <SignUp {...props} signup={signup} />}
      />
      <Route
        exact
        path="/password_reset/"
        render={(props) => <ForgotPassword {...props} />}
      />
      <Route
        exact
        path="/password_reset/:token"
        render={(props) => <PasswordReset {...props} />}
      />
      <Route
        path="/"
        render={(props) => <Login {...props} login={login} />}
        default
      />
    </Switch>
  );
}
