import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import { Placeholder, Button } from "semantic-ui-react";
import Header from "../components/Header";
import BreadcrumbTop from "../components/BreadcrumbTop";
import Axios from "axios";
import { ROOT_URL } from "../constants/defaultValues";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { confirmAlert } from "react-confirm-alert";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function Users({ logout, properties }) {
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const sections = [{ content: "Usuarios", destination: "/null" }];
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <Button size="tiny" color="black" onClick={handleClick}>
        Exportar datos
      </Button>
    );
  };
  const notify = (ms) =>
    toast.success(ms, {
      autoClose: 4000,
      theme: "dark",
    });

  useEffect(() => {
    async function getData() {
      try {
        const { data: users } = await Axios.get(`${ROOT_URL}/users/?view=dashboard`);
        setUsers(users);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    getData();
  }, []);

  const dateFormatter = (cell, row) => {
    const date = row.FechaCreacion;
    return new Date(date * 1000).toLocaleString();
  };

  const dateFormatter2 = (cell, row) => {
    const date = row.FinSuscripcion;
    return new Date(date * 1000).toLocaleString();
  };

  const column = [
    {
      filter: textFilter(),
      dataField: "Id",
      text: "Id",
    },
    {
      filter: textFilter(),
      dataField: "Nombre",
      text: "Nombre",
      sort: true,
    },
    {
      filter: textFilter(),
      dataField: "Propiedad",
      text: "Propiedad",
      sort: true,
    },
    {
      filter: textFilter(),
      dataField: "FechaCreacion",
      text: "Fecha Inicio",
      sort: true,
      formatter: dateFormatter,
      //csvFormatter: (cell, row, rowIndex) => unixToDate(row.FechaAlta),
      csvFormatter: (cell, row, rowIndex) =>
        new Date(row.FechaCreacion * 1000).toLocaleString(),
    },
    {
      filter: textFilter(),
      dataField: "FinSuscripcion",
      text: "Fecha Fin",
      sort: true,
      formatter: dateFormatter2,
      csvFormatter: (cell, row, rowIndex) =>
        new Date(row.FinSuscripcion * 1000).toLocaleString(),
    },
    {
      filter: textFilter(),
      dataField: "Email",
      text: "Email",
      sort: true,
    },
    {
      filter: textFilter(),
      dataField: "Movil",
      text: "Movil",
    },
    {
      dataField: "Prueba",
      text: "Es Prueba",
      sort: true,
      style: (cell, row, rowIndex, colIndex) => {
        if (row.Prueba) {
          return {
            backgroundColor: "#FCFFF5",
            color: "#B36907",
            fontWeight: "bold",
          };
        }
        return {
          backgroundColor: "#F7FFE6",
          color: "#425B28",
          fontWeight: "bold",
        };
      },
    },
    {
      dataField: "none",
      text: "Acciones",
      formatter: buttonsFormatter,
      csvExport: false,
    },
  ];

  function buttonsFormatter(cell, row) {
    const userId = row.Id;
    const emailId = row.Email;
    return (
      <>
        <Button
          icon="trash"
          color="red"
          size="mini"
          onClick={() => erase(userId, emailId)}
          disabled={row.Rol === "SisAdmin" || row.Rol === "Admin"}
        />
        <NavLink
          to={`/user/${userId}`}
          onClick={(e) => {
            if (row.Rol === "SisAdmin") e.preventDefault();
          }}
        >
          <Button
            icon="eye"
            color="blue"
            size="mini"
            disabled={row.Rol === "SisAdmin"}
          />
        </NavLink>
      </>
    );
  }

  const erase = (userId, emailId) => {
    confirmAlert({
      message: "¿Desea borrar " + userId + "? Esta acción no se puede deshacer",
      buttons: [
        {
          label: "Si",
          onClick: () => deleteItem(userId, emailId),
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  async function deleteItem(userId, emailId) {
    setLoading(true);
    const copyUsers = [...users];
    
    try {
      await Axios.delete(`${ROOT_URL}/users/${userId}?Email=${emailId}`)
        .then((response) => {
          setLoading(false);
          notify(response.data.message);
          copyUsers.splice(
            copyUsers.findIndex((val) => val.Id === userId),
            1
          );
          setUsers(copyUsers);
        })
        .catch((error) => {
          setLoading(false);
          notify(error.response.data.message);
        });
    } catch (error) {
      console.log("error");
      setLoading(false);
    }
  }

  return (
    <>
      <Header logout={logout} properties={properties} />
      <ToastContainer />
      <div className="padd-top-6 padd-bot-2 index-bg">
        <BreadcrumbTop sections={sections} />
        <Container>
          <Row>
            <Col sm="12">
              <Card className={!loading ? null : "trans"}>
                <CardBody>
                  <Row>
                    {users? (
                      <ToolkitProvider
                        bootstrap4
                        keyField="Id"
                        data={users}
                        columns={column}
                        exportCSV={{
                          onlyExportFiltered: true,
                          exportAll: false,
                        }}
                      >
                        {(props) => (
                          <>
                            <div className="text-zero top-right-button-container">
                              <MyExportCSV {...props.csvProps} />
                            </div>
                            <br />
                            <br />
                            <BootstrapTable
                              {...props.baseProps}
                              pagination={paginationFactory()}
                              filter={filterFactory()}
                              hover
                              striped
                              condensed
                              wrapperClasses="table-responsive"
                              bordered={false}
                            />
                          </>
                        )}
                      </ToolkitProvider>
                    ) : (
                      <Placeholder fluid>
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                        <br />
                        <Placeholder.Line length="full" />
                      </Placeholder>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
