import React, { useEffect } from "react";
import { Row, Col, Container, CardBody, Card as ReactCard } from "reactstrap";
import { Image, Icon, Button, Card } from "semantic-ui-react";
import { carouselImages } from "../data/data";
import Header from "../components/Header";
import { NavLink } from "react-router-dom";
import Axios from "axios";
import { ROOT_URL } from "../constants/defaultValues";
import { useTranslation } from "react-i18next";
import IncompleteProfile from "../containers/IncompleteProfile";

export default function Dashboard({ logout, properties, user }) {
  const role = properties.role === "Usuario" ? true : false;
  const dataCompleted = properties.data_completed;
  const { t, i18n } = useTranslation();
  const isEspanish = i18n.resolvedLanguage === "es" ? true : false;

  useEffect(() => {
    const setTitle = () => {
      document.title = "Yarvix";
    };
    setTitle();
  }, []);

  async function handleClick(course) {
    if (role) {
      const payload = {
        IdUsuario: user,
        IdCurso: course,
      };
      try {
        await Axios.post(`${ROOT_URL}/records/`, payload);
      } catch (error) {
        console.log("error");
      }
    } else {
      return;
    }
  }

  return (
    <>
      <Header logout={logout} properties={properties} />
      <div className="padd-top-6 padd-bot-2 dashboard-bg">
        <Container>
          <Row>
            <Col sm="12">
              {!dataCompleted && <IncompleteProfile />}
              <ReactCard className="trans2">
                <CardBody>
                  <Row>
                    <Card.Group itemsPerRow={3} stackable centered>
                      {carouselImages.map((course, index) => {
                        return (
                          <Card className={course.Color} key={index}>
                            <Image src={course.Image} wrapped ui={false} />
                            <Card.Content
                              style={{
                                backgroundColor: `${course.Color} !important`,
                              }}
                            >
                              <Card.Header className="text-whi">
                                {isEspanish ? course.Content : course.ContentCA}
                              </Card.Header>
                              <Card.Description className="text-whi">
                                {isEspanish
                                  ? course.Description
                                  : course.DescriptionCA}
                              </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                              <NavLink to={`/courses/course=${course.Course}`}>
                                <Button
                                  animated
                                  color={course.DataColor}
                                  size="small"
                                  fluid
                                  onClick={() => handleClick(course.Course)}
                                >
                                  <Button.Content visible>
                                    {t("ir_curso")}
                                  </Button.Content>
                                  <Button.Content hidden>
                                    <Icon size="small" name="arrow right" />
                                  </Button.Content>
                                </Button>
                              </NavLink>
                            </Card.Content>
                          </Card>
                        );
                      })}
                    </Card.Group>
                  </Row>
                </CardBody>
              </ReactCard>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
