import React, { useReducer, useState, forwardRef, useEffect } from "react";
import {
  Button,
  Modal as MdlSmt,
  Form as FormSec,
  Icon,
  Form,
  Message,
  Label,
} from "semantic-ui-react";
import Axios from "axios";
import { ROOT_URL } from "../constants/defaultValues";
import DatePicker from "react-datepicker";
import { courses } from "../data/data";
import { MultiSelect } from "react-multi-select-component";
import { FormGroup } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

function exampleReducer(state, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { open: true, dimmer: action.dimmer };
    case "CLOSE_MODAL":
      return { open: false };
    default:
      throw new Error();
  }
}

export default function UserCouponModal({ props, history }) {
  const [state, dispatch] = useReducer(exampleReducer, {
    open: false,
    dimmer: undefined,
  });
  const { open, dimmer } = state;
  const [loading, setLoading] = useState(false);
  const [infoUser, setInfoUser] = useState(null);
  const initialState = {
    value: false,
    msg: "",
    existingRecord: false,
  };
  const [info, setInfo] = useState(initialState);
  const [startDate, setStartDate] = useState(null);
  const [selected, setSelected] = useState([]);
  const options = [];
  const subscription = [
    { key: "co", text: "Colegio", value: "Colegio" }, //Son usuarios de colegios
    { key: "w", text: "Web", value: "Web" }, //Ingresaron directamente en Web
    { key: "t", text: "Tienda", value: "Tienda" }, //Compraron una tarjeta
    { key: "r", text: "Referencia", value: "Referencia" }, //Se obtuvieron mediante un link de referencia
  ];
  const test = [
    { key: "t", text: "Si", value: true },
    { key: "f", text: "No", value: false },
  ];

  const notify = (ms) =>
    toast.success(ms, {
      autoClose: 4000,
      theme: "dark",
    });

  useEffect(() => {
    function getData() {
      courses.map((course) => {
        options.push({
          label: course.Title,
          value: course.Title,
          disabled: !course.Available,
        });
      });
    }
    getData();
  }, [options]);

  const Calendar = forwardRef(({ value, onClick }, ref) => (
    <Button as="div" labelPosition="right" onClick={onClick} ref={ref}>
      <Button icon type="button">
        <Icon name="calendar alternate" />
      </Button>
      <Label as="a" basic>
        {value}
      </Label>
    </Button>
  ));

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const payload = {
      Prueba: infoUser.Prueba,
      Suscripcion: infoUser.Suscripcion,
      Cursos:
        selected === null || selected.length < 1
          ? []
          : selected.map((s) => s.value),
      FinSuscripcion: parseInt(
        (new Date(startDate).getTime() / 1000).toFixed(0)
      ),
    };

    try {
      await Axios.patch(`${ROOT_URL}/users/${infoUser.Id}`, payload)
        .then((response) => {
          setLoading(false);
          dispatch({ type: "CLOSE_MODAL" });
          setInfo(initialState);
          notify(response.data.message);
        })
        .catch((error) => {
          notify(error.response.data.message);
          setLoading(false);
          return;
        });
    } catch (error) {
      notify("Error no controlado");
      setLoading(false);
      return;
    }
  }

  //Gestiona los cambios en los inputs
  function handleInputChange(e) {
    setInfoUser({
      ...infoUser,
      [e.target.name]: e.target.value,
    });
  }

  const getid = async () => {
    const id = infoUser.Id;
    setLoading(true);

    await Axios.get(`${ROOT_URL}/users/${id}`)
      .then((response) => {
        const payload = response.data;

        setInfoUser(payload);
        const selected = payload.Cursos
          ? payload.Cursos.map((course) => ({ label: course, value: course }))
          : [];
        setSelected(selected);
        setStartDate(new Date(payload.FinSuscripcion) * 1000);
        setInfo({
          ...info,
          value: false,
          existingRecord: true,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setInfo({
          value: true,
          msg: error.response.data.message,
          existingRecord: false,
        });
      });
    setLoading(false);
  };

  function timeDifference(date1, date2) {
    let difference = date1 - date2;
    const daysDifference = Math.floor(difference / 60 / 60 / 24);
    return daysDifference;
  }

  return (
    <div>
      <ToastContainer />
      <Button
        animated
        color="purple"
        size="small"
        fluid
        onClick={() => dispatch({ type: "OPEN_MODAL", dimmer: "blurring" })}
      >
        <Button.Content visible>{props.Title}</Button.Content>
        <Button.Content hidden>
          <Icon size="small" name="arrow right" />
        </Button.Content>
      </Button>

      <MdlSmt
        centered
        size="small"
        dimmer={dimmer}
        open={open}
        onClose={() => dispatch({ type: "CLOSE_MODAL" })}
        as={Form}
        onSubmit={(e) => handleSubmit(e)}
      >
        <MdlSmt.Header>
          {props.Title + "  "}
          {info.value && (
            <Message negative content={info.msg} compact size="tiny" />
          )}
        </MdlSmt.Header>
        <MdlSmt.Content>
          <FormSec.Group widths="equal">
            <Form.Input
              fluid
              label="Id"
              required
              type="text"
              placeholder="Id del usuario"
              name="Id"
              onChange={handleInputChange}
              readOnly={info.existingRecord}
              error={info.value}
            />
            {info.existingRecord && (
              <>
                <FormSec.Input
                  fluid
                  label="Email"
                  value={infoUser.Email}
                  readOnly
                />
                <FormSec.Input
                  fluid
                  label="Nombre"
                  value={infoUser.Nombre}
                  readOnly
                />
              </>
            )}
          </FormSec.Group>
          {info.existingRecord && (
            <>
              <FormSec.Group widths="equal">
                <FormSec.Input
                  fluid
                  label="Fecha de registro"
                  value={new Date(
                    infoUser.FechaCreacion * 1000
                  ).toLocaleDateString()}
                  readOnly
                />
                <FormSec.Input
                  fluid
                  label="Termino de suscripción"
                  value={new Date(
                    infoUser.FinSuscripcion * 1000
                  ).toLocaleDateString()}
                  readOnly
                />
                <FormSec.Input
                  fluid
                  label="Días restantes de suscripción"
                  value={timeDifference(
                    infoUser.FinSuscripcion,
                    Math.round(+new Date() / 1000)
                  )}
                  readOnly
                />
              </FormSec.Group>
              <Form>
                <FormSec.Group widths="equal">
                  <Form.Select
                    fluid
                    label="Tipo suscripción"
                    required
                    onChange={(e, { value }) => {
                      setInfoUser({
                        ...infoUser,
                        Suscripcion: value,
                      });
                    }}
                    options={subscription}
                    selection
                    value={infoUser.Suscripcion}
                  />
                  <Form.Select
                    fluid
                    label="¿Periodo de prueba?"
                    required
                    onChange={(e, { value }) => {
                      setInfoUser({
                        ...infoUser,
                        Prueba: value,
                      });
                    }}
                    options={test}
                    selection
                    value={infoUser.Prueba}
                  />
                  <FormGroup>
                    <label>Extender fecha suscripción </label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      minDate={new Date(
                        infoUser.FinSuscripcion * 1000
                      ).toLocaleDateString()}
                      customInput={<Calendar />}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>Cursos</label>
                    <MultiSelect
                      options={options}
                      value={selected}
                      onChange={setSelected}
                      labelledBy="Select"
                    />
                  </FormGroup>
                </FormSec.Group>
              </Form>
            </>
          )}
        </MdlSmt.Content>
        <MdlSmt.Actions>
          {info.existingRecord ? (
            <Button color="green" type="submit" loading={loading}>
              Actualizar
            </Button>
          ) : (
            <Button
              color="blue"
              onClick={getid}
              loading={loading}
              type="button"
            >
              Buscar
            </Button>
          )}
          <Button
            color="red"
            onClick={() => dispatch({ type: "CLOSE_MODAL" })}
            type="button"
          >
            Cancelar
          </Button>
        </MdlSmt.Actions>
      </MdlSmt>
    </div>
  );
}
