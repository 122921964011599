import React from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import { Tab } from "semantic-ui-react";
import { projects } from "../data/data";
import Tabs from "../components/Tabs";
import Header from "../components/Header";
import BreadcrumbTop from "../components/BreadcrumbTop";
import Iframe from "../components/Iframe";
import { useTranslation } from "react-i18next";
import IncompleteProfile from "../containers/IncompleteProfile";

export default function Courses({ match, logout, properties }) {
  const selectedCourse = match.params.course;
  const result = projects.filter((course) => course.Course === selectedCourse);
  const dataCompleted = properties.data_completed;
  const { t, i18n } = useTranslation();
  const isEspanish = i18n.resolvedLanguage === "es" ? true : false;
  let title;
  let project = t("proyecto");

  if (result[0].Course === "robotica-y-programacion") {
    title = `${t("curso_robo")}`;
  } else if (result[0].Course === "bionica") {
    title = `${t("curso_bion")}`;
  } else if (result[0].Course === "domotica-y-iot") {
    title = `${t("curso_domo")}`;
  } else if (result[0].Course === "energias-renovables") {
    title = `${t("curso_ener")}`;
  } else if (result[0].Course === "scratch") {
    title = `${t("curso_scra")}`;
  } else if (result[0].Course === "minecraft") {
    title = `${t("curso_mine")}`;
  } else {
    title = t("iniciando");
    project = t("paso");
  }

  const sections = [{ content: title, destination: "/null" }];

  const panes = result.map((res) => ({
    menuItem:
      project === t("paso")
        ? `${isEspanish ? res.Title : res.TitleCA}`
        : isEspanish
        ? res.Title
        : res.TitleCA === t("archivos")
        ? `${isEspanish ? res.Title : res.TitleCA}`
        : `${t("proyecto")} ${res.Project}`,
    render: () => (
      <Tab.Pane>
        {project === t("paso") ? (
          <Iframe props={result[res.Project - 1]} properties={properties} />
        ) : (
          <Tabs props={result[res.Project - 1]} properties={properties} />
        )}
      </Tab.Pane>
    ),
  }));

  return (
    <>
      <Header logout={logout} properties={properties} />
      <div className="padd-top-4-5 padd-bot-2 index-bg">
        <BreadcrumbTop sections={sections} />
        <Container>
          <Row>
            <Col sm="12">
              {!dataCompleted && <IncompleteProfile />}
              <Card className="trans">
                <CardBody>
                  <Row>
                    <Tab
                      menu={
                        project === t("paso")
                          ? {
                              pointing: false,
                              attached: "top",
                              borderless: true,
                            }
                          : { fluid: true, vertical: true, tabular: true }
                      }
                      panes={panes}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
