import React, { useEffect } from "react";
import { Card, CardBody, FormGroup, Col, Badge } from "reactstrap";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import LogoLogin from "../images/form-login.png";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Button } from "semantic-ui-react";

//import Axios from "axios";

export default function Login({ login }) {
  const notify = (ms) =>
    toast.error(ms, {
      autoClose: 4000,
      theme: "dark",
    });
  
  const [stripeURL, ] = React.useState("");

  useEffect(() => {
    const setPage = async () => {
      document.title = "Entrar a Yarvix";
    };
    setPage();
  }, [stripeURL]);

  //formSchema se encarga de las validaciones y tipo de dato de los campos
  const formSchema = Yup.object().shape({
    Contrasena: Yup.string().required("Escriba su contraseña"),
    Email: Yup.string().email().required("Proporcione un email"),
  });

  async function onSubmit(values) {
    try {
      await login(values.Email.toLowerCase(), values.Contrasena);
    } catch (error) {
      notify(error.response.data.message);
    }
  }

  return (
    <div className="login-bg">
        <Container className="col">
          <Col sm="12" md={{ size: 4, offset: 0 }} className="padd-top-8">
            <Card className="bg-color-segment">
              <CardBody>
                <span className="center padd-bot-3">
                  <img src={LogoLogin} alt="logo" className="img-fluid" />
                </span>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    Contrasena: "",
                    Email: "",
                  }}
                  validationSchema={formSchema}
                  onSubmit={onSubmit}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <FormGroup row>
                        <Col sm="12" className="padd-bot-3">
                          <FormGroup>
                            <Field
                              type="email"
                              name="Email"
                              className="input-form"
                              placeholder="Email"
                              required
                            />
                            {errors.Email && touched.Email ? (
                              <div>
                                <Badge className="badge-color">
                                  {errors.Email}
                                </Badge>
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col sm="12" className="padd-bot-3">
                          <FormGroup>
                            <Field
                              type="password"
                              name="Contrasena"
                              className="input-form"
                              placeholder="Contraseña"
                              required
                            />
                            {errors.Contrasena && touched.Contrasena ? (
                              <div>
                                <Badge className="badge-color">
                                  {errors.Contrasena}
                                </Badge>
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col sm="12" className="padd-bot-3">
                          <FormGroup>
                            <Button
                              content="Entrar"
                              color="purple"
                              type="submit"
                              fluid
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12" className="padd-bot-3">
                          <FormGroup>
                            <NavLink to={"/password_reset"}>
                              <Button
                                fluid
                                type="button"
                                basic
                                color="blue"
                                content="¿Olvidaste tu contraseña?"
                              />
                            </NavLink>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <NavLink to={"/signup/reference=Yarvix"}>
                              <Button
                                fluid
                                type="button"
                                color="pink"
                                content="¿Eres nuevo en Yarvix? Regístrate"
                              />
                            </NavLink>
                          </FormGroup>
                        </Col>
                        <Col sm="12" className="padd-bot-3">
                          <FormGroup>
                          </FormGroup>
                        </Col>
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Container>
    </div>
  );
}
